import { createIcon } from "@chakra-ui/icons";

const PNGIcon = createIcon({
  displayName: "PNGIcon",
  viewBox: "0 0 41 40",
  path: (
    <>
      <path
        opacity=".2"
        d="M26.797 6.511h-13.25c-1.39 0-2.454 1.064-2.454 2.454V34.32c0 1.39 1.063 2.454 2.454 2.454h18.812c1.39 0 2.453-1.063 2.453-2.454V14.527c0-.41-.163-.818-.49-1.145l-6.38-6.38a1.615 1.615 0 0 0-1.145-.49Z"
        fill="#F5A070"
      />
      <path
        d="M31.134 10.192h-5.726a1.64 1.64 0 0 1-1.636-1.636V2.831M31.131 27.368v3.272c0 1.39-1.063 2.453-2.453 2.453H9.866c-1.39 0-2.454-1.063-2.454-2.453v-3.272m23.72 0h-15.54m15.54 0h1.636V16.735H27.45m-20.039-4.09v4.09m0 0h15.132m-15.132 0H5.776v10.633h1.636m0 0h4.09"
        stroke="#F5A070"
        strokeWidth="1.954"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.413 8.147V5.284c0-1.39 1.063-2.453 2.454-2.453H23.77l7.362 7.36v2.455"
        stroke="#F5A070"
        strokeWidth="1.954"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.913 24.506V22.46m0 0v-2.863h1.84c.79 0 1.432.641 1.432 1.431v0c0 .791-.641 1.432-1.432 1.432h-1.84Zm5.726 2.045v-4.908l3.271 4.908v-4.908m5.726.818v-.167a.651.651 0 0 0-.652-.65h-.909c-.719 0-1.302.582-1.302 1.302v2.302c0 .72.583 1.303 1.302 1.303h1.56V22.87h-.817"
        stroke="#F5A070"
        strokeWidth="1.303"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default PNGIcon;
