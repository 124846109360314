import { createIcon } from "@chakra-ui/icons";

const Shield = createIcon({
  displayName: "Shield",
  viewBox: "0 0 232 361",
  path: (
    <>
      <path
        d="M106.077 29.988c-28.965 11.58-72.642-4.292-97.031-16.783-3.351-1.717-7.457.652-7.579 4.416-4.476 138.588 11.705 169.272 28.701 212.62 13.492 34.412 58.93 97.545 81.704 127.117a4.948 4.948 0 0 0 4.853 1.819c83.2-16.169 103.088-72.613 102.564-99.115l-1.408-127.377c-.024-2.209-1.503-4.122-3.579-4.875-35.281-12.796-80.639-66.812-101.734-96.091-1.463-2.03-4.167-2.66-6.491-1.732Z"
        fill="#457AB0"
        stroke="#1BEBE7"
        strokeOpacity=".42"
      />
      <path
        d="M106.041 54.443c-24.165 9.364-59.915-3.619-80.654-14.28-3.37-1.733-7.512.63-7.628 4.417C14.11 164.04 27.828 190.664 42.236 228.224c11.42 29.768 49.732 84.262 69.189 110.13a4.94 4.94 0 0 0 4.905 1.864c70.303-14.166 87.116-63.05 86.666-86.032l-1.219-110.258c-.024-2.197-1.481-4.098-3.533-4.883-29.704-11.369-67.614-57.372-85.619-82.81-1.466-2.073-4.217-2.709-6.584-1.792Z"
        fill="#401970"
        fillOpacity=".6"
        stroke="#1BEBE7"
      />
      <path
        d="m16.077 4.186-13.665 8.99c1.596-.638 5.555-1.245 8.62 1.437 3.818 3.341 59.012 29.992 97.785 14.525.26-.104.512-.202.767-.086 6.354 2.894 58.013 84.15 104.863 98.606 1.018.314 2.094.252 3.089-.126l11.069-4.207c-.017-1.582-1.025-2.963-2.503-3.529-35.52-13.586-81.208-67.952-102.066-96.763-1.328-1.833-3.701-2.55-5.856-1.86C79.576 33.538 40.458 16.573 21.753 4.23c-1.724-1.137-3.95-1.18-5.676-.044Z"
        fill="#D9D9D9"
      />
      <path
        d="M123.463 356.927c48.309-2.614 83.745-45.307 89.177-53.689 13.073-16.787 16.868-56.588 17.132-74.39l-1.167-105.523m0 0c-.017-1.582-1.025-2.963-2.503-3.529-35.52-13.586-81.208-67.952-102.066-96.763-1.328-1.833-3.701-2.55-5.856-1.86C79.576 33.538 40.458 16.573 21.753 4.23c-1.724-1.137-3.95-1.18-5.676-.044l-13.665 8.99c1.596-.638 5.555-1.245 8.62 1.437 3.818 3.341 59.012 29.992 97.785 14.525.26-.104.512-.202.767-.086 6.354 2.894 58.013 84.15 104.863 98.606 1.018.314 2.094.252 3.089-.126l11.069-4.207ZM215.135 128.648l13.466-5.56"
        stroke="#1BEBE7"
        strokeOpacity=".42"
      />
      <path
        d="M106.091 97.911c-15.507 5.128-37.273-2.223-51.235-9.012-3.408-1.657-7.571.668-7.677 4.456-2.205 79.173 7.047 97.201 16.76 122.521 7.672 19.998 33.152 56.37 46.58 74.292a4.85 4.85 0 0 0 4.877 1.843c47.113-9.903 58.417-42.881 58.112-58.44l-.817-73.971c-.025-2.197-1.485-4.092-3.511-4.943-19.624-8.237-44.09-37.618-56.436-54.84-1.48-2.063-4.242-2.703-6.653-1.906Z"
        fill="#401970"
        fillOpacity=".6"
        stroke="#1BEBE7"
        stroke-width="4"
      />
    </>
  ),
});

export default Shield;
