import React, { useEffect } from "react";

import { Box } from "@chakra-ui/react";

import IntroFold from "../components/Home/IntroFold";
import ProtectedFold from "../components/Home/ProtectedFold";
import KnowMoreFold from "../components/Home/KnowMoreFold";
import AboutUsFold from "../components/Home/AboutUsFold";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <IntroFold />
      <ProtectedFold />
      <KnowMoreFold />
      <AboutUsFold />
    </Box>
  );
}
