import { createIcon } from "@chakra-ui/icons";

const InvestmentIcon = createIcon({
  displayName: "InvestmentIcon",
  viewBox: "0 0 37 37",
  path: (
    <>
      <rect
        opacity=".2"
        x="5.033"
        y="7.563"
        width="29.932"
        height="23.946"
        rx="2.993"
        fill="#E8CDFF"
      />
      <path
        d="m14.756 14.822.051.051a2.89 2.89 0 0 0 2.044.847v0m0-6.286h.55c.565 0 1.067.362 1.246.898v0m-1.796-.898h-.3c-.826 0-1.496.67-1.496 1.497v0c0 .826.67 1.496 1.497 1.496H17c.91 0 1.647.737 1.647 1.646v0c0 .91-.737 1.647-1.646 1.647h-.15m0-6.286V7.938m0 7.782v1.197"
        stroke="#B865FF"
        strokeWidth="2.245"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.607 14.766a1.87 1.87 0 1 0 0-3.742 1.87 1.87 0 0 0 0 3.742ZM8.396 14.766a1.87 1.87 0 1 0 0-3.742 1.87 1.87 0 0 0 0 3.742Z"
        fill="#B865FF"
      />
      <path
        d="M2.412 10.93V7.811a2.993 2.993 0 0 1 3.007-2.993l23.198.112a2.993 2.993 0 0 1 2.979 2.993v11.239m0 3.741v2.245a2.993 2.993 0 0 1-2.994 2.993H5.405a2.993 2.993 0 0 1-2.993-2.993V15.42M8.396 19.91h17.211m-14.966 3.742h12.721"
        stroke="#B865FF"
        strokeWidth="2.245"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default InvestmentIcon;
