import { createIcon } from "@chakra-ui/icons";

const KeyIcon = createIcon({
  displayName: "KeyIcon",
  viewBox: "0 0 77 110",
  path: (
    <>
      <path
        d="m12.158.743 29.134 2.15a20 20 0 0 1 6.189 1.47l1.424.59a15 15 0 0 1 9.229 14.738l-.067 1.13A15 15 0 0 1 45.663 34.72l-3.103.54a10 10 0 0 1-2.384.125L9.944 33.352c-5.497-.37-9.66-5.116-9.31-10.615l.809-12.658C1.797 4.529 6.612.334 12.158.743Z"
        fill="url(#key-icon-a)"
      />
      <path
        d="M31.956 90.937c.409 2.728-2.979 2.67-4.001 2.385l.08-4.771 6.478-6.648 11.591-9.035.682.34c-1.59 2.387-4.432 6.649-6.136 6.649-4.262.09-2.898 1.705-3.41 4.943-.511 3.24-4.943 1.023-5.455 1.876-.511.852-.34.852.17 4.261Z"
        fill="#0DA7A4"
      />
      <path
        d="m53.435 73.549-.34-2.728 14.318-21.137L58.55 34c4.773 0 14.967 2.59 17.558 12.955 2.591 10.364-2.557 18.638-5.455 21.479v3.114a2 2 0 0 1-2 2h-.045l.852 9.205-.877.97a4.229 4.229 0 0 0-.224 5.406l.565.738a3.188 3.188 0 0 1-.323 4.235 3.188 3.188 0 0 0-.01 4.587l.557.54a3.937 3.937 0 0 1 .21 5.433l-3.99 4.513-5.436-5.029a5 5 0 0 1-1.578-3.15l-2.872-27.448h-2.046Z"
        fill="#177F7D"
      />
      <path
        d="M59.06 33.478C50.334 18.341 45.65 21.83 44.4 25.467c.682-8.012 8.865-7.149 12.956-2.035 3.273 4.091 4.716 9.035 4.943 10.91l-3.239-.864Z"
        fill="#3A1347"
      />
      <path
        d="M40.991 41.27c5.319-9.41 15.285-8.354 19.603-6.649 5.797 2.727 11.08 8.523 11.08 16.535C71.539 69.703 58.436 71.953 51.9 70.76l-2.592 2.474a2 2 0 0 1-2.49.218l-.202-.135-4.307 5.14a3.26 3.26 0 0 1-2.5 1.167 3.26 3.26 0 0 0-3.211 2.699l-.319 1.82a2.61 2.61 0 0 1-2.733 2.154 2.61 2.61 0 0 0-2.754 2.913l.075.63a3.138 3.138 0 0 1-3.037 3.508l-5.761.146-.409-7.272a5 5 0 0 1 .996-3.286L37.07 63.771l-.587-1.027c-.456-.798-.287-1.792.275-2.52 1.783-2.31 1.676-4.44 1.335-5.317-.625-2.273-.92-8.183 2.898-13.638Z"
        fill="#1BEBE7"
      />
      <ellipse cx="58.547" cy="47.809" rx="3.921" ry="5.114" fill="#151326" />
      <path
        d="M55.822 45.081c-8.728-2.864-11.137-13.807-11.25-18.921 0-1.932.886-5.898 4.431-6.308 8.32-.545 12.217 9.433 13.126 14.49l2.898.682c-.545-2.864-3.636-7.785-5.114-9.887-2.102-2.898-7.875-8.49-14.149-7.671-6.273.818-7.046 8.41-6.648 12.103.114 2.841 1.944 10.194 8.353 16.876 6.41 6.682 12.37 5.132 14.7 3.484.363-1.69.442-2.576.064-3.71a2.009 2.009 0 0 0-.16-.353c-.44-.78-2.086-.659-2.953-.434-1.194.31-2.68-.145-3.298-.35Z"
        fill="#5B2C9F"
      />
      <path
        d="m21.898 88.38 16.706-22.672.601.554L22.67 88.593l-.772-.213Z"
        fill="#242424"
        fill-opacity=".3"
      />
      <path
        d="m35.706 61.365 1.364 2.386c1.364 2.728 7.842 8.864 11.251 10.57l3.58-3.58c-2.387-3.75-13.978-12.956-14.149-12.104-.136.682-1.477 2.16-2.046 2.728Z"
        fill="url(#key-icon-b)"
      />
      <path
        d="M54.588 30.731c2.225-1.39 3.36-6.624 3.476-9.174l-5.91-3.129c.278 2.504-1.044 4.288-1.739 4.868l4.173 7.435Z"
        fill="#81C9F2"
      />
      <path
        d="m10.49 12.123.762 3.055 3.14.22-2.67 1.669.763 3.055-2.412-2.024-2.67 1.669 1.179-2.92-2.413-2.024 3.142.22 1.179-2.92ZM20.944 12.77l.667 3.077 3.133.316-2.721 1.585.667 3.078-2.349-2.098-2.72 1.585 1.27-2.881-2.349-2.098 3.133.317 1.27-2.882ZM30.977 13.316l.805 3.044 3.144.175-2.647 1.706.806 3.044-2.441-1.989-2.646 1.706 1.138-2.936-2.441-1.989 3.144.175 1.138-2.936Z"
        fill="#5B2C9F"
      />
      <defs>
        <linearGradient
          id="key-icon-a"
          x1="29.206"
          y1="4.868"
          x2="28.163"
          y2="35.465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7DC8F4" />
          <stop offset=".644" stopColor="#84CAF0" />
          <stop offset="1" stopColor="#379DD7" />
        </linearGradient>
        <linearGradient
          id="key-icon-b"
          x1="39.701"
          y1="58.628"
          x2="27.451"
          y2="68.704"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1BEBE7" />
          <stop offset="1" stopColor="#15CAC7" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default KeyIcon;
