import { createIcon } from "@chakra-ui/icons";

const GraphIcon = createIcon({
  displayName: "GraphIcon",
  viewBox: "0 0 127 119",
  path: (
    <>
      <path
        d="m36.933 53.046-13.346 1.186 19.409.04 10.11-.384-16.173-.842Z"
        fill="#BAF8F6"
      />
      <path
        d="m113.645 27.017 11.789-21.745a2.347 2.347 0 0 0-.651-2.994l-17.847 26.899.955.27a5 5 0 0 0 5.754-2.43ZM102.9 25.282l2.018 2.026-42.483 28.166a1 1 0 0 1-.596.166l-4.289-.188 45.35-30.17ZM37.623 35.304l-2.11-2.504L9.59 55.41c-.695.608-.268 1.753.655 1.754l2.916.006 24.462-21.866Z"
        fill="#73298B"
      />
      <path
        d="m45.163 14.939 14.575 17.91-3.466 2.492-19.758-21.766c4.003-1.99 7.434.08 8.65 1.364Z"
        fill="#DEB5F6"
      />
      <path
        d="m23.939 71.035-7.308 1.138-.081 39.413 7.31-1.908.079-38.643ZM80.185 58.392l-9.547 1.193-.122 59.224 9.622-4.402.047-56.015ZM53.104 53.888l-10.11.383-.346 60.828 10.34-4.602.116-56.61Z"
        fill="#16BCB9"
      />
      <path
        d="M16.63 72.172.098 71.177l10.386-1.901 17.108 1.189-10.962 1.707Z"
        fill="#BAF8F6"
      />
      <path
        d="m.1 71.178 16.531.995-.081 39.412-16.53-2.341.08-38.066ZM50.454 59.553l20.187.042-.122 59.214-20.182-2.54.117-56.716Z"
        fill="#70F3F1"
      />
      <path
        d="M30.226 17.199 5.524 40.498a10 10 0 0 0-2.109 11.694l.757 1.535c1.495 3.035 5.51 3.74 7.95 1.396L35.535 32.62l14.746 18.24a10 10 0 0 0 13.457 1.944l39.386-27.185.069.119c1.949 3.367 6.828 3.316 8.706-.091l12.881-23.37L99.593.702c-3.74-.234-6.4 3.577-4.888 7.006l.206.467-33.327 23.459a5 5 0 0 1-6.79-.975L44.91 18.244c-3.645-4.578-10.426-5.061-14.684-1.045Z"
        fill="#AC4CCC"
      />
      <path
        d="m23.586 54.232 19.41.04-.125 60.653-19.001-1.756-.284-58.937Z"
        fill="#70F3F1"
      />
      <path
        d="m58.916 57.84-8.462 1.713 20.186.042 9.547-1.203-21.27-.552Z"
        fill="#BAF8F6"
      />
    </>
  ),
});

export default GraphIcon;
