import { createIcon } from "@chakra-ui/icons";

const ParkIcon = createIcon({
  displayName: "Park Icon",
  viewBox: "0 0 520 520",
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M204.256 98.754c-81.575 52.39-126.89 113.36-135.958 182.942-14.116 108.301 82.734 161.308 131.809 113.685 49.075-47.634 19.663-108.084-15.871-124.616-35.533-16.521-57.265-10.768-53.473-32.857 3.792-22.079 54.34-83.309 98.757-111.822a8.116 8.116 0 0 0 1.235-11.05l-10.985-14.289c-4.767-6.197-9.339-5.958-15.514-2.004v.01Zm214.782 0c-81.575 52.39-126.902 113.37-135.958 182.942-14.116 108.301 82.734 161.308 131.809 113.685 49.075-47.634 19.662-108.084-15.882-124.616-35.533-16.521-57.265-10.768-53.473-32.857 3.791-22.079 54.351-83.309 98.767-111.822a8.11 8.11 0 0 0 1.224-11.05l-10.985-14.289c-4.766-6.197-9.338-5.958-15.502-2.004v.01Z"
        fill="currentColor"
        opacity=".3"
      />
    </>
  ),
});

export default ParkIcon;
