import { createIcon } from "@chakra-ui/icons";

const Email = createIcon({
  displayName: "Email",
  viewBox: "0 0 18 18",
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.494 1.5c2.498 0 4.381 2.038 4.381 4.74v5.151c0 1.383-.489 2.633-1.378 3.519-.797.794-1.831 1.215-2.99 1.215h-7.39c-1.158 0-2.191-.42-2.99-1.215C1.24 14.024.75 12.774.75 11.391V6.24c0-2.702 1.883-4.74 4.38-4.74h7.364Zm0 1.125H5.131c-1.887 0-3.256 1.52-3.256 3.615v5.151c0 1.082.372 2.049 1.047 2.722.582.581 1.342.887 2.197.887h7.375c.002-.002.008 0 .012 0 .856 0 1.615-.306 2.197-.887.676-.673 1.047-1.64 1.047-2.722V6.24c0-2.095-1.37-3.615-3.256-3.615Zm1.182 3.472a.563.563 0 0 1-.082.79l-3.332 2.71a2.281 2.281 0 0 1-2.85.005L4.048 6.888a.563.563 0 0 1 .706-.876l3.361 2.71a1.158 1.158 0 0 0 1.442-.004l3.327-2.704a.562.562 0 0 1 .792.082Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width=".4"
      />
    </>
  ),
});

export default Email;
