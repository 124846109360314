import { createIcon } from "@chakra-ui/icons";

const LinkedIn = createIcon({
  displayName: "LinkedIn",
  viewBox: "0 0 22 22",
  path: (
    <>
      <g clip-path="url(#clip0_41_575)">
        <path
          d="M20.372 0H1.623C.726 0 0 .709 0 1.586V20.41C0 21.287.726 22 1.624 22h18.747C21.27 22 22 21.287 22 20.415V1.585C22 .71 21.27 0 20.372 0ZM6.527 18.747H3.26V8.246h3.266v10.501ZM4.894 6.815a1.892 1.892 0 1 1-.007-3.783 1.892 1.892 0 0 1 .007 3.783Zm13.853 11.932h-3.261v-5.104c0-1.216-.022-2.785-1.697-2.785-1.698 0-1.955 1.328-1.955 2.699v5.19H8.577V8.246h3.128V9.68h.043c.434-.825 1.5-1.697 3.085-1.697 3.304 0 3.914 2.174 3.914 5.001v5.762Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_41_575">
          <path fill="currentColor" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export default LinkedIn;
