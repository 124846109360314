import { createIcon } from "@chakra-ui/icons";

const HomeIcon = createIcon({
  displayName: "HomeIcon",
  viewBox: "0 0 118 104",
  path: (
    <>
      <path
        d="m20.227 93.546 49.412 7.314 4.298-6.526 1.897-39.125-29.711-44.76-28.744 32.544.402 6.429c-.425 8.078-1.487 26.01-2.328 33.12-.841 7.11 2.832 10.299 4.774 11.004Z"
        fill="#70F3F1"
      />
      <path
        d="m55.74 67.88-1.612 22.929-1.757.73-22.387-3.28c.57-6.077 1.707-19.425 1.701-24.204-.005-4.78 4.563-6.223 6.847-6.347 3.822.269 11.872 1.09 13.499 2.23 2.914 2.252 3.686 6.233 3.709 7.941Z"
        fill="#5B2C9F"
      />
      <path
        d="m98.608 89.41 2.28-32.439-27.19-13.86-4.059 57.749 20.088-2.212a10 10 0 0 0 8.88-9.239Z"
        fill="#16BCB9"
      />
      <path
        d="m6.74 48.646 11.1-.073c-.102-3.421 1.408-5.875 2.175-6.675l26.025-24.2-2.31-3.577L6.74 48.646Z"
        fill="#0DA09E"
      />
      <path
        d="m108.84 56.986-34.213 3.26.525-.807a5 5 0 0 0-.128-5.645L42.816 8.936l37.815-3.742 31.802 43.86c1.41 1.944 1.225 4.63-.423 6.378a5.02 5.02 0 0 1-3.17 1.554Z"
        fill="#AC4CCC"
      />
      <path
        d="m89.679 16.072-4 2.28-1.374 19.532.79.909 5.155-.491 1.463-20.807-2.034-1.423Z"
        fill="#16DBD7"
      />
      <path
        d="m85.709 17.926-1.403 19.957-8.17-11.242.686-9.767 4.76-.945 4.127 1.997Z"
        fill="#70F3F1"
      />
      <path
        d="m6.367 48.161.373.485 36.992-34.524 31.38 45.304.615-.602a5 5 0 0 0 .587-6.462l-29.137-41.2a5 5 0 0 0-7.522-.742L6.888 41.48a5 5 0 0 0-.521 6.681Z"
        fill="#5B2C9F"
      />
      <path
        d="m95.164 10.91-8.127.281-.508 7.219 8.187-1.131.448-6.37Z"
        fill="#0DA09E"
      />
      <path
        d="m74.327 9.872 12.71 1.297-.509 7.241-12.682-1.7.481-6.838Z"
        fill="#16DBD7"
      />
      <path
        d="m83.729 9.68-9.401.192 12.708 1.32 8.128-.282-11.435-1.23Z"
        fill="#70F3F1"
      />
    </>
  ),
});

export default HomeIcon;
