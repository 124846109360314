import { createIcon } from "@chakra-ui/icons";

const FemaleBlueAvatar = createIcon({
  displayName: "FemaleBlueAvatar",
  viewBox: "0 0 93 93",
  path: (
    <>
      <path
        opacity=".5"
        d="M46.044 92.09c25.43 0 46.045-20.616 46.045-46.046S71.474 0 46.045 0C20.614 0 0 20.615 0 46.044 0 71.475 20.615 92.09 46.044 92.09Z"
        fill="url(#paint0_linear_41_1669)"
      />
      <path
        d="M46.044 90.865c24.892 0 45.07-20.18 45.07-45.071 0-24.892-20.178-45.071-45.07-45.071S.973 20.902.973 45.793c0 24.893 20.179 45.072 45.07 45.072Z"
        fill="#364147"
      />
      <path
        d="M45.7 64.781c11.035 0 19.98-8.945 19.98-19.98S56.735 24.82 45.7 24.82c-11.036 0-19.981 8.946-19.981 19.98 0 11.036 8.945 19.981 19.98 19.981Z"
        fill="#333"
      />
      <path
        opacity=".1"
        d="M38.68 62.923H52.62v11.152a6.969 6.969 0 1 1-13.937 0V62.923h-.004Z"
        fill="#000"
      />
      <path
        d="M40.007 62.406h11.28a1.331 1.331 0 0 1 1.33 1.33v9.822a6.97 6.97 0 0 1-13.937 0v-9.821a1.331 1.331 0 0 1 1.327-1.331Z"
        fill="#BE7C5E"
      />
      <path
        opacity=".1"
        d="M38.707 68.186a20.065 20.065 0 0 0 13.942.04v-1.712H38.707v1.672Z"
        fill="#000"
      />
      <path
        d="M45.7 69.12c11.035 0 19.98-8.946 19.98-19.981 0-11.035-8.945-19.98-19.98-19.98-11.036 0-19.981 8.945-19.981 19.98s8.945 19.98 19.98 19.98Z"
        fill="#BE7C5E"
      />
      <path
        d="M33.59 40.9c.235-.863.53-1.813 1.304-2.262.612-.357 1.37-.302 2.078-.266 5.523.302 10.997-.957 16.37-2.273 3.07-.75 6.356-1.664 8.343-4.14 1.986-2.475-.604-10.254-3.628-11.172-1.986-.608-4.29.525-6.174-.365-.838-.398-1.454-1.14-2.177-1.72-1.482-1.193-3.385-1.674-5.229-2.126-2.153-.533-4.481-1.053-6.528-.2-1.589.66-2.75 2.055-4.255 2.881-2.865 1.59-6.357.85-9.639.843-1.108 0-2.431.222-2.872 1.24-.298.69-.06 1.477-.028 2.228.095 2.384-1.871 4.24-3.52 5.96-1.65 1.72-3.207 4.176-2.166 6.313.398.815 1.125 1.43 1.538 2.237.711 1.399.354 3.087.612 4.633a11.657 11.657 0 0 0 1.3 3.337c1.223 2.384 7.37 15.217 10.194 11.955 1.367-1.589 1.59-6.182 2.142-8.248.762-2.97 1.541-5.921 2.336-8.856Z"
        fill="#333"
      />
      <path
        d="M31.484 51.897c1.027 0 1.86-1.56 1.86-3.485 0-1.924-.833-3.484-1.86-3.484-1.027 0-1.859 1.56-1.859 3.484 0 1.925.832 3.485 1.86 3.485Z"
        fill="#BE7C5E"
      />
      <path
        d="M46.13 90.818c24.892 0 45.07-20.179 45.07-45.071S71.023.676 46.13.676 1.059 20.855 1.059 45.746c0 24.893 20.179 45.072 45.07 45.072Z"
        fill="#080F16"
      />
      <path
        d="M46.294 71.388c-8.73.043-15.688-.932-24.13-3.161-3.274-.864-11.191-3.87-11.191-3.87 4.426-8.539 8.4-17.28 12.129-26.144 2.541-6.046 5.884-13.21 12.429-15.689 10.595-4.013 24.908-.11 27.92 11.81 1.383 5.478 1.008 11.448 1.194 17.05.202 5.973.3 11.953.9 17.903-5.739 2.124-13.18 2.073-19.25 2.101Z"
        fill="#F2D4CF"
      />
      <path
        d="M74 80.346C57.16 93.31 34.224 93.114 17.5 80l11.707-5.652c.375-.178.74-.374 1.095-.59l.283-.176a11.725 11.725 0 0 0 3.993-4.345c1.007-1.902 1.34-4.003 1.354-6.144 0-1.441-.078-2.882-.093-4.325a559.96 559.96 0 0 1-.025-2.396l.504.252 1.31.678 7.625 1.304 7.839 1.335.237 8.061.118 3.998 2.89 1.176 2.267.917L74 80.345Z"
        fill="#F2A196"
      />
      <path
        d="M53.332 67.99c-2.534.45-5.14.247-7.574-.592-5.436-1.826-9.097-6.232-9.442-10.771l1.31.678 7.63 1.302 7.84 1.335.236 8.048Z"
        fill="#E88870"
      />
      <circle cx="28.509" cy="48.309" r="4.282" fill="#F2A196" />
      <circle cx="30.021" cy="47.805" r="4.282" fill="#E88870" />
      <path
        d="M59.474 41.563c3.136 10.867-2.97 23.49-7.623 24.006-11.245 1.26-19.23-4.481-22.369-15.348-3.138-10.867 1.033-21.618 9.32-24.009 8.288-2.39 17.533 4.481 20.672 15.35Z"
        fill="#F2A196"
      />
      <circle
        cx="42.708"
        cy="45.664"
        r="4.786"
        stroke="#fff"
        stroke-width=".5"
      />
      <circle
        cx="56.095"
        cy="44.908"
        r="4.786"
        stroke="#fff"
        stroke-width=".5"
      />
      <path
        d="M48.872 28.2c-1.086 4.71-3.451 8.71-7.774 11.623-4.665 3.232-10.028 3.688-15.399 4.75.136-3.302.21-6.839 1.912-9.783a20.02 20.02 0 0 1 8.14-7.648c3.73-1.927 9.5-3.816 13.693-2.481a28.741 28.741 0 0 1-.572 3.539Z"
        fill="#F2D4CF"
      />
      <path
        d="M49.316 42.807c-.02.086 2.466 9.784 2.466 9.784h-3.15M52.2 40.233a7.844 7.844 0 0 1 5.755-.542M38.316 41.165a9.26 9.26 0 0 1 7.867-.252"
        stroke="#00214E"
        stroke-width=".5"
      />
      <path d="m47.492 45.664 3.814-.325" stroke="#fff" stroke-width=".5" />
      <path
        d="M74 80.345C57.16 93.311 34.224 93.114 17.5 80l11.707-5.653a11.792 11.792 0 0 0 2.151-1.31 11.676 11.676 0 0 0 3.22-3.8c.594-1.14.99-2.374 1.168-3.648.178.172.365.335.56.489a23.971 23.971 0 0 0 7.63 3.763 23.56 23.56 0 0 0 8.312.88c.388-.031.779-.069 1.166-.097l.038 1.28v.083l3.048 1.24L74 80.344Z"
        fill="#6C63FF"
      />
      <path
        d="M41.75 47.848a2.605 2.605 0 0 0 2.77-2.519M52.805 44.777c.013.664.317 1.288.831 1.708a6.783 6.783 0 0 0 1.68 1.008M39.977 55.422a6.203 6.203 0 0 1 4.479-2.995M46.457 35.401h5.96M45.004 37.37h7.416"
        stroke="#00214E"
        stroke-width=".5"
      />
      <path
        d="M42.762 55.11a.471.471 0 0 1 .678-.126c.522.368 1.481 1.15 2.839 1.421 1.854.368 3.464-.373 3.85.106.213.27-.051.597-.558 1.008a4.97 4.97 0 0 1-3.74.927c-1.786-.333-3.124-2.401-3.124-3.132a.461.461 0 0 1 .055-.204Z"
        fill="#fff"
      />
      <path
        d="M42.424 43.925c1.536-.015 1.582 2.333.045 2.376h-.045c-1.54.017-1.585-2.33-.048-2.373h.048v-.003ZM55.084 43.515c1.428-.017 1.468 2.164.043 2.202h-.063c-1.426.015-1.469-2.164-.043-2.204h.063v.002Z"
        fill="#00214E"
      />
      <defs>
        <linearGradient
          id="paint0_linear_41_1669"
          x1="46.044"
          y1="92.089"
          x2="46.044"
          y2=".004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="gray" stop-opacity=".25" />
          <stop offset=".54" stop-color="gray" stop-opacity=".12" />
          <stop offset="1" stop-color="gray" stop-opacity=".1" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default FemaleBlueAvatar;
