import HealthIcon from "../../icons/HeathIcon";
import IdIcon from "../../icons/IdIcon";
import InsuranceIcon from "../../icons/InsuranceIcon";
import InvestmentIcon from "../../icons/InvestmentIcon";
import LegalIcon from "../../icons/LegalIcon";
import PNGIcon from "../../icons/PNGIcon";
import RealState from "../../icons/RealStateIcon";
import VehicleIcon from "../../icons/VehicleIcon";

export const OrganizedContent = {
  header: "Stay Organized",
  content:
    "WALLT provides you with an intuitive and customizable digital vault to organize, categorize and store your information so that your family does not struggle to locate it in moments of grief.",
  list: [
    "Manage and assign nominees for each document",
    "Import documents directly from your DigiLocker",
    "Update your investments via Consolidated Account Statement (CAS) on WALLT",
    "Give viewing access for documents to safeguard your estate's execution",
  ],
};

export const ProtectedContent = {
  header: "Stay Protected",
  content:
    "WALLT keeps your information private, confidential and encrypted. Only you and your nominees can access it—WALLT itself cannot. Our top priority is your privacy, giving you complete control over who can access your information.",
  list: [
    "End-to-end 256 bit encryption of all your information",
    "2 Factor authentication to keep your account secure",
    "API driven KYC to verify identity of all people accessing your information",
    "Verified partners of DigiLocker",
  ],
};

export const AssuredContent = {
  header: "Stay Assured",
  content:
    "WALLT diligently follows up with you periodically through non-intrusive channels to check on your wellbeing. This is a simple and secure way to ensure that we reach out to your loved ones in a timely manner in case of your passing.",
  list: [
    "Periodic non-intrusive follow ups",
    "Robust escalation matrix to mitigate missed follow ups",
    "Prompt contact with nominees to safeguard your legacy",
    "Verification of intimation of death using Government records",
  ],
};

export const KnowMoreList = [
  {
    title: "Insurance",
    icon: <PNGIcon width="100%" height="100%" fill="none" />,
    content:
      "Store a digital copy of all your insurance covers, nominee details, agent contacts, and any relevant instructions.",
  },
  {
    title: "Finances",
    icon: <InsuranceIcon width="100%" height="100%" fill="none" />,
    content:
      "Collate information about all your bank accounts, fixed deposits, bank locker details, utstanding debts, expected payments, PF accounts, NPS details, and other financial instruments, and ensure your loved ones are informed about all of them.",
  },
  {
    title: "IDs",
    icon: <IdIcon width="100%" height="100%" fill="none" />,
    content:
      "Save your Aadhar, PAN Card, Driving License, Voter ID, Passport, and any other identification record to assist your loved ones in emergencies or when closing official records.",
  },
  {
    title: "Investment",
    icon: <InvestmentIcon width="100%" height="100%" fill="none" />,
    content:
      "Secure your investments for your family by updating all your assets such as demat accounts for stocks, mutual fund investments, Systematic Investment Plans (SIPs), alternate investments, and crypto currencies.",
  },
  {
    title: "Vehicle",
    icon: <VehicleIcon width="100%" height="100%" fill="none" />,
    content:
      "Ensure that your family can continue using your vehicles hassle free and has a copy of the registration certificate, transfer document and NOCs in one place.",
  },
  {
    title: "Legal",
    icon: <LegalIcon width="100%" height="100%" fill="none" />,
    content:
      "Ensure your family has access to all essential legal documents, such as trust deeds, estate plans, and powers of attorney etc. Use WALLT to store and share the most recent copy of your will with your heirs and executors as needed.",
  },
  {
    title: "Real Estate",
    icon: <RealState width="100%" height="100%" fill="none" />,
    content:
      "Provide access to all your title deeds, lease agreements, lease holds, receipt of statutory dues, and other legal documents for immovable properties to your intended nominees to protect their rights and interests.",
  },
  {
    title: "Medical Records",
    icon: <HealthIcon width="100%" height="100%" fill="none" />,
    content:
      "WALLT allows you to share access to your health and medical records, including health insurance details and living will (advance medical directives), ensuring your family can access these documents in case you are incapacitated.",
  },
];

export const slides = [
  {
    badge: "The Ambitious Climber",
    title: "Just started to build your career?",
    subTitle: "Take charge of your financial future with WALLT",
    content:
      "Organize <strong>personal finance, stocks, bank accounts, employee benefit schemes</strong> in one secure place, ensuring that your family is aware of the wealth that you create for them. Stay ahead by storing all your important financial information on one secure platform so that your ambitions and financial goals are secured with your family.",
  },
  {
    badge: "The Steady Achiever",
    title: "Want to protect your family?",
    subTitle: "Secure your assets with WALLT",
    content:
      "Effortlessly manage your <strong>investments, tax saving instruments, real estate holdings, bank accounts and financial assets</strong> for your family on one trusted platform. Ensure that your stability and financial growth extends to your family.",
  },
  {
    badge: "The Seasoned Learner",
    title: "Planning for your retirement?",
    subTitle: "Plan ahead with WALLT",
    content:
      "Organize important documents like your <strong>Will, medical insurance, and life insurance policies</strong> and choose who to share them with. Ensure peace of mind and effective end-of-life planning by continuously updating and managing your estate and assets with WALLT.",
  },
];

export const aboutUsSlides = [
  {
    content:
      "As a retiree, having a tool that safely stores all my important documents has been a game changer. Knowing my financial assets, investments, property papers, and will are securely organized and can be easily passed on to my family gives me tremendous peace of mind. This platform has made end-of-life planning simple and stress-free for both me and my loved ones.",
    author: "- Suraj S, 62 years.",
  },
  {
    content:
      "I have always been meticulous about my finances, but it wasn’t until my cousin passed away unexpectedly that I realized how important end-of-life planning is. WALLT has become like my guardian, my protector — watching over my legacy and ensuring that when the time comes, my family won’t have to face any confusion or struggle. Everything they need will be exactly where it should be.",
    author: "- Vivek A, 34 years.",
  },
  {
    content:
      "After watching my colleague’s family struggle when they lost their mother, I knew I had to be better prepared. I've organized everything from my insurance policies to my last wishes on WALLT. It’s not just about storing data; it’s about making sure your loved ones aren’t left with unanswered questions or financial uncertainty.",
    author: "- Natasha R, 43 years.",
  },
];

export const amountData = [
  {
    title: "Protect",
    price: "1499",
    duration: "/ year",
    profits: [
      "1 GB Storage",
      "Check in Frequency- 3 Months",
      // "No Visitor Access",
      "No Dedicated Relationship Manager",
    ],
  },
  {
    title: "Protect",
    badge: "+",
    price: "6499",
    duration: "/ 5 years",
    discount: {
      price: "1499",
      after: "1300 / year",
      save: "(save 13%)",
    },
    profits: [
      "2 GB Storage",
      "Check in Frequency - Configurable 3 or 6 Months",
      // "No Visitor Access",
      "No Dedicated Relationship Manager",
    ],
    tag: "Most Popular",
  },
  {
    title: "Protect",
    badge: "PRO",
    price: "10999",
    duration: "/ 10 years",
    discount: {
      price: "1499",
      after: "1099 / year",
      save: "(save 27%)",
    },
    profits: [
      "5 GB Storage",
      "Check in Frequency - Configurable 3 or 6 Months",
      // "Visitor Access",
      "Dedicated Relationship Manager",
    ],
  },
];
