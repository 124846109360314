import { createIcon } from "@chakra-ui/icons";

const HomeHexa2 = createIcon({
  displayName: "HomeHexa2",
  viewBox: "0 0 369 332",
  path: (
    <>
      <path
        opacity=".4"
        d="M0 108.42v114.839c0 18.222 11.929 35.043 31.255 44.164l121.973 57.43c19.352 9.101 43.184 9.101 62.536 0l121.972-57.43c19.352-9.1 31.264-25.942 31.264-44.164V108.42c0-18.222-11.929-35.043-31.264-44.164L215.764 6.826c-19.352-9.101-43.184-9.101-62.536 0L31.255 64.255C11.903 73.356 0 90.198 0 108.42Z"
        fill="url(#home-hexa-2-a)"
        fillOpacity=".9"
      />
      <defs>
        <linearGradient
          id="home-hexa-2-a"
          x1="-119.384"
          y1="-24.325"
          x2="512.957"
          y2="570.858"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#FDD2FD" />
          <stop offset=".23" stopColor="#E87AF5" />
          <stop offset=".37" stopColor="#9A30CE" />
          <stop offset=".46" stopColor="#7E26BE" />
          <stop offset=".55" stopColor="#651DB1" />
          <stop offset=".63" stopColor="#5015A5" />
          <stop offset=".75" stopColor="#390D98" />
          <stop offset=".86" stopColor="#310B94" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default HomeHexa2;
