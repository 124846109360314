import { createIcon } from "@chakra-ui/icons";

const IdIcon = createIcon({
  displayName: "Id Icon",
  viewBox: "0 0 37 37",
  path: (
    <>
      <path
        opacity=".2"
        d="M5.408 9.528c0-.827.67-1.497 1.497-1.497h11.598c.827 0 1.497.67 1.497 1.497v1.87h11.598a2.245 2.245 0 0 1 2.245 2.245v15.34c0 .827-.67 1.497-1.496 1.497H30.85a.748.748 0 0 1-.748-.748.748.748 0 0 0-.748-.748H26.36a.748.748 0 0 0-.748.748.748.748 0 0 1-.748.748H14.387a.748.748 0 0 1-.748-.748.748.748 0 0 0-.748-.748H9.898a.748.748 0 0 0-.749.748.748.748 0 0 1-.748.748H6.905c-.827 0-1.497-.67-1.497-1.496V9.528Z"
        fill="#FFFDCD"
      />
      <path
        d="M3.158 10.276V7.283c0-.827.67-1.497 1.497-1.497h11.224c.827 0 1.497.67 1.497 1.497v1.496h11.972c.827 0 1.497.67 1.497 1.497v9.354M3.158 15.888v9.728a1.87 1.87 0 0 0 1.87 1.87h.936a.935.935 0 0 0 .935-.935v0c0-.516.42-.935.936-.935h2.619c.516 0 .935.419.935.935v0c0 .517.419.936.936.936h9.353a.935.935 0 0 0 .936-.936v0c0-.516.418-.935.935-.935h2.62c.516 0 .934.419.934.935v0c0 .517.42.936.936.936h.935a1.87 1.87 0 0 0 1.871-1.871v-2.245M19.627 13.644h6.734m-6.734 4.49h6.734"
        stroke="#D5CF4F"
        strokeWidth="2.245"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.391 16.262a2.62 2.62 0 1 0 0-5.238 2.62 2.62 0 0 0 0 5.239Zm0 0a3.741 3.741 0 0 0-3.74 3.742m3.74-3.742a3.742 3.742 0 0 1 3.742 3.742"
        stroke="#D5CF4F"
        strokeWidth="2.245"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default IdIcon;
