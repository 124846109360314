import { createIcon } from "@chakra-ui/icons";

const HamburgerIcon = createIcon({
  displayName: "HamburgerIcon",
  viewBox: "0 0 31 15",
  path: (
    <>
      <path
        d="M1 16h29M1 1h17.4M1 8.5h11.6"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
});

export default HamburgerIcon;
