import { createIcon } from "@chakra-ui/icons";

const FemaleAvatar = createIcon({
  displayName: "FemaleAvatar",
  viewBox: "0 0 75 75",
  path: (
    <>
      <path
        opacity=".5"
        d="M37.237 74.474C16.67 74.474 0 57.802 0 37.237 0 16.67 16.672 0 37.237 0s37.237 16.672 37.237 37.237-16.672 37.237-37.237 37.237Z"
        fill="url(#female-avatar-a)"
      />
      <path
        d="M37.237 73.484c-20.13 0-36.45-16.32-36.45-36.45 0-20.13 16.32-36.45 36.45-36.45 20.13 0 36.45 16.32 36.45 36.45 0 20.13-16.32 36.45-36.45 36.45Z"
        fill="#364147"
      />
      <path
        d="M37.517 52.39c-8.924 0-16.158-7.234-16.158-16.159 0-8.924 7.234-16.159 16.158-16.159 8.925 0 16.16 7.235 16.16 16.16 0 8.924-7.235 16.158-16.16 16.158Z"
        fill="#333"
      />
      <path
        opacity=".1"
        d="M43.192 50.886H31.917v9.02a5.635 5.635 0 1 0 11.272 0v-9.02h.003Z"
        fill="#000"
      />
      <path
        d="M42.119 50.468h-9.122a1.076 1.076 0 0 0-1.077 1.076v7.943a5.636 5.636 0 0 0 11.272 0v-7.943a1.077 1.077 0 0 0-1.073-1.076Z"
        fill="#BE7C5E"
      />
      <path
        opacity=".1"
        d="M43.171 55.143a16.226 16.226 0 0 1-11.274.032v-1.384H43.17v1.352Z"
        fill="#000"
      />
      <path
        d="M37.517 55.898c-8.924 0-16.158-7.235-16.158-16.159s7.234-16.158 16.158-16.158c8.925 0 16.16 7.234 16.16 16.158 0 8.925-7.235 16.16-16.16 16.16Z"
        fill="#BE7C5E"
      />
      <path
        d="M47.307 33.076c-.19-.698-.428-1.466-1.054-1.829-.495-.289-1.109-.244-1.68-.215-4.467.244-8.895-.774-13.239-1.838-2.483-.607-5.14-1.346-6.747-3.348-1.607-2.002.488-8.293 2.933-9.035 1.607-.492 3.47.424 4.994-.296.677-.321 1.175-.922 1.76-1.391 1.199-.964 2.738-1.353 4.229-1.72 1.741-.43 3.624-.85 5.279-.16 1.285.534 2.223 1.662 3.441 2.33 2.317 1.285 5.141.687 7.795.681.897 0 1.967.18 2.323 1.003.241.559.048 1.195.023 1.802-.077 1.928 1.513 3.428 2.847 4.82 1.333 1.391 2.593 3.377 1.75 5.105-.32.659-.909 1.157-1.243 1.81-.575 1.13-.286 2.496-.495 3.746a9.426 9.426 0 0 1-1.05 2.699c-.99 1.928-5.96 12.306-8.245 9.668-1.105-1.285-1.285-5-1.732-6.67a572.118 572.118 0 0 0-1.89-7.162Z"
        fill="#333"
      />
      <path
        d="M49.013 41.97c-.83 0-1.503-1.262-1.503-2.819 0-1.556.673-2.817 1.503-2.817.83 0 1.504 1.261 1.504 2.817 0 1.557-.673 2.818-1.504 2.818Z"
        fill="#BE7C5E"
      />
      <path
        d="M37.167 73.446c-20.13 0-36.45-16.32-36.45-36.45 0-20.13 16.32-36.45 36.45-36.45 20.13 0 36.45 16.32 36.45 36.45 0 20.13-16.32 36.45-36.45 36.45Z"
        fill="#080F16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.127 22.681c-5.69 3.745-6.814 10.715-6.892 17.07-.033 2.686-.008 5.36.017 8.032.049 5.197.098 10.38-.281 15.61.026-.373 6.026-1.27 6.712-1.301 1.44-.063 2.93.074 4.404.209.726.066 1.448.132 2.158.174 1.238.078 2.504.11 3.777.14 2.44.061 4.906.122 7.25.515.456.076.904.153 1.35.23 2.356.406 4.61.794 7.103.934.197.034.4-.006.57-.113.19-.157.19-.44.19-.688 0-2.959-.132-5.917-.263-8.873-.186-4.175-.371-8.346-.186-12.509a25.248 25.248 0 0 0-1.708-10.377 20.129 20.129 0 0 0-5.06-7.467 14.74 14.74 0 0 0-1.94-1.494c-5.1-3.308-12.063-3.473-17.201-.092Zm16.732 14.355-2.674 1.914-8.617-8.69 1.083-.692a13.475 13.475 0 0 0 4.146 4.404 24.655 24.655 0 0 0 6.063 3.064Z"
        fill="#3F0D16"
      />
      <path
        d="M15.825 65.51c12.69 9.77 30.388 9.691 42.99-.19l-9.237-4.332a9.04 9.04 0 0 1-.826-.444l-.212-.133a8.834 8.834 0 0 1-3.009-3.274c-.759-1.433-1.01-3.016-1.02-4.63 0-1.085.058-2.171.07-3.259l.018-1.805-.38.19-.986.51-5.744.982-5.907 1.006-.178 6.074-.09 3.012-2.177.881-1.708.69-11.603 4.722Z"
        fill="#DB967C"
      />
      <path
        d="M31.4 56.2c1.91.338 3.874.185 5.708-.447 4.096-1.376 6.854-4.696 7.114-8.116l-.987.51-5.747.978-5.907 1.006-.18 6.068Z"
        fill="#AA5C46"
      />
      <circle
        cx="3.227"
        cy="3.227"
        r="3.227"
        transform="matrix(-1 0 0 1 53.33 38.142)"
        fill="#DB967C"
      />
      <circle
        cx="3.227"
        cy="3.227"
        r="3.227"
        transform="matrix(-1 0 0 1 52.191 37.762)"
        fill="#AA5C46"
      />
      <path
        d="M26.775 36.284c-2.365 8.19 2.238 17.7 5.743 18.091 8.472.949 14.49-3.377 16.856-11.567 2.365-8.19-.778-16.288-7.023-18.09-6.245-1.8-13.213 3.377-15.576 11.566Z"
        fill="#DB967C"
      />
      <path
        d="M32.254 35.283a5.924 5.924 0 0 0-4.34-.41"
        stroke="#00214E"
        strokeWidth=".5"
      />
      <path
        d="M15.825 65.51c12.69 9.77 30.388 9.691 42.99-.19l-9.237-4.332a8.893 8.893 0 0 1-1.62-.987 8.8 8.8 0 0 1-2.427-2.864 8.483 8.483 0 0 1-.88-2.749c-.135.13-.275.253-.422.368a18.054 18.054 0 0 1-5.747 2.838 17.75 17.75 0 0 1-6.264.663c-.293-.023-.587-.052-.879-.073l-.028.965v.062l-2.297.934-13.188 5.364Z"
        fill="#5B2C9F"
      />
      <path
        d="M39.9 40.986a1.963 1.963 0 0 1-2.088-1.899M32.276 48.853a1.962 1.962 0 0 1-.062-2.82M31.795 38.708c-.011.499-.24.969-.627 1.285-.382.314-.808.57-1.265.759M41.462 46.73a4.866 4.866 0 0 0-.868-1.073 4.676 4.676 0 0 0-2.507-1.183"
        stroke="#00214E"
        strokeWidth=".5"
      />
      <path
        d="M39.362 46.494a.353.353 0 0 0-.51-.095c-.394.277-1.115.865-2.14 1.069-1.397.279-2.608-.28-2.899.08-.163.202.036.451.418.768a3.738 3.738 0 0 0 2.82.7c1.344-.252 2.354-1.808 2.354-2.359a.327.327 0 0 0-.043-.163Z"
        fill="#fff"
      />
      <path
        d="M42.713 35.985a6.972 6.972 0 0 0-5.926-.19"
        stroke="#00214E"
        strokeWidth=".5"
      />
      <circle
        cx=".38"
        cy=".38"
        r=".38"
        transform="matrix(-1 0 0 1 35.364 34.454)"
        fill="#E72547"
      />
      <path
        d="M33.514 22.432c.088.894.232 1.782.43 2.658.34 1.546.916 3.03 1.709 4.4a13.407 13.407 0 0 0 4.145 4.365 24.737 24.737 0 0 0 6.063 3.037 38.52 38.52 0 0 0 5.212 1.373c-.059-1.449 0-3.24-.178-4.922-.133-1.207-.399-2.36-.934-3.288a15.097 15.097 0 0 0-6.11-5.751l-.023-.011c-2.807-1.445-7.156-2.87-10.314-1.86Z"
        fill="#3F0D16"
      />
      <path
        d="M35.66 29.497a13.406 13.406 0 0 0 4.145 4.366 24.736 24.736 0 0 0 6.063 3.037l5.034-3.55 2.424-1.708a19.948 19.948 0 0 0-5.06-7.403 14.736 14.736 0 0 0-1.94-1.488l-2.468 1.56-8.198 5.186Z"
        fill="url(#female-avatar-b)"
      />
      <circle
        cx="3.606"
        cy="3.606"
        r="3.606"
        transform="matrix(-1 0 0 1 43.714 35.733)"
        stroke="#fff"
        strokeWidth=".5"
      />
      <circle
        cx="3.606"
        cy="3.606"
        r="3.606"
        transform="matrix(-1 0 0 1 33.627 35.164)"
        stroke="#fff"
        strokeWidth=".5"
      />
      <path d="m36.501 39.34-2.874-.245" stroke="#fff" strokeWidth=".5" />
      <path
        d="M35.056 37.187c.016.065-1.503 6.139-1.503 6.139h2.373"
        stroke="#00214E"
        strokeWidth=".5"
      />
      <circle
        cx=".759"
        cy=".759"
        r=".759"
        transform="matrix(-1 0 0 1 41.053 38.06)"
        fill="#00214E"
      />
      <circle
        cx=".759"
        cy=".759"
        r=".759"
        transform="matrix(-1 0 0 1 30.991 38.06)"
        fill="#00214E"
      />
      <defs>
        <linearGradient
          id="female-avatar-a"
          x1="37.237"
          y1="74.474"
          x2="37.237"
          y2=".003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity=".25" />
          <stop offset=".54" stopColor="gray" stopOpacity=".12" />
          <stop offset="1" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="female-avatar-b"
          x1="41.477"
          y1="42.226"
          x2="33.845"
          y2="26.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".29" stopColor="#3F0D16" />
          <stop offset=".51" stopColor="#886060" />
          <stop offset=".79" stopColor="#3F0D16" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default FemaleAvatar;
