import { useMediaQuery } from "@chakra-ui/react";
import React, { FC } from "react";

interface IUploadProps {
  isLargerThan1280: boolean;
  isLargerThan768: boolean;
  isLargerThanHD: boolean;
  isLargerThan480: boolean;
}

const ResponsiveContext = React.createContext<IUploadProps | null>(null);

export const useResponsive = (): IUploadProps => {
  const context = React.useContext(ResponsiveContext);
  if (!context) {
    throw new Error(`useResponsive must be used within a ResponsiveProvider`);
  }
  const { isLargerThan768, isLargerThan1280, isLargerThanHD, isLargerThan480 } =
    context;

  return {
    isLargerThan768,
    isLargerThan1280,
    isLargerThanHD,
    isLargerThan480,
  };
};

interface IProps {
  children: React.ReactNode;
}

const ResponsiveProvider: FC<IProps> = (props) => {
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)", {
    ssr: true,
    fallback: false,
  });
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
  const [isLargerThanHD] = useMediaQuery(["(min-width: 1920px)"]);

  const value = React.useMemo(
    () => ({
      isLargerThan1280,
      isLargerThan768,
      isLargerThanHD,
      isLargerThan480,
    }),
    [isLargerThan1280, isLargerThan768, isLargerThanHD, isLargerThan480]
  );

  return <ResponsiveContext.Provider value={value} {...props} />;
};

export default ResponsiveProvider;
