import React from "react";

import { Box, HStack, Stack, Text } from "@chakra-ui/react";

import WalltFullLogo from "../icons/WalltFullLogo";
import Facebook from "../icons/Facebook";
import Twitter from "../icons/Twitter";
import Instagram from "../icons/Instagram";
import LinkedIn from "../icons/LinkedIn";
import Phone from "../icons/Phone";
import Email from "../icons/Email";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      borderTop="1px solid"
      border-image="linear-gradient(90deg, rgba(255, 255, 255, 0) 13.32%, rgba(255, 255, 255, 0.44) 50%, rgba(255, 255, 255, 0) 86.82%)"
      background="#08141C"
      padding={{ base: "30px 20px", md: "30px 80px" }}
      position="relative"
    >
      <HStack
        flexDirection={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        gap={5}
      >
        <Stack flex="2" color="white">
          <Stack width="320px" spacing="55px">
            <Stack gap={4}>
              <WalltFullLogo width="146px" height="40px" />
              <Text>
                A digital custodian to facilitate end-of-life planning
              </Text>
            </Stack>
            {/* <HStack gap={10} display={{ base: "none", md: "flex" }}>
              <Facebook fontSize="22px" />
              <Twitter fontSize="22px" />
              <Instagram fontSize="22px" />
              <LinkedIn fontSize="22px" />
            </HStack> */}
          </Stack>
        </Stack>
        <HStack
          flex="1"
          fontSize="16px"
          color="#A99EB9"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection={{ base: "column", md: "row" }}
          gap={5}
        >
          <Stack gap={3} flex={1}>
            <Text color="white">Quick links</Text>
            <Text>Home</Text>
            <Text>Why Wallt</Text>
            <Text>Features</Text>
            <Text>Resources</Text>
            <Text>FAQs</Text>
            <Stack gap={3} display={{ base: "flex", md: "none" }}>
              {/* <Text>Security</Text> */}
              <Link to="/privacy">
                <Text>Privacy Policy</Text>
              </Link>
              <Link to="/terms">
                <Text>Terms of Service</Text>
              </Link>
            </Stack>
          </Stack>
          <Stack gap={3} flex={1}>
            <Text color="white">Contact information</Text>
            <Text>
              <Phone fontSize="18px" mr="3px" /> +91 95208 72655
            </Text>
            <Text>
              <Email fontSize="18px" mr="4px" /> support@wallt.in
            </Text>
            <Text>
              Address: 50-51, Mini Complex, Doon Cooperative, Industrial Estate,
              Patel Nagar, Dehradun - 248001, Uttarakhand, India
            </Text>
          </Stack>
        </HStack>
      </HStack>
      <HStack
        mt="60px"
        color="#A99EB9"
        fontSize="16px"
        flexDirection={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
      >
        <HStack gap={10} display={{ base: "flex", md: "none" }}>
          <Facebook fontSize="22px" />
          <Twitter fontSize="22px" />
          <Instagram fontSize="22px" />
          <LinkedIn fontSize="22px" />
        </HStack>
        <Box flex="2">
          <Text>©2024 Wallt, All Rights Reserved.</Text>
        </Box>
        <HStack
          display={{ base: "none", md: "flex" }}
          flex="1"
          justifyContent="space-between"
        >
          {/* <Text>Security</Text> */}
          <Link to="/privacy">
            <Text>Privacy Policy</Text>
          </Link>
          <Link to="/terms">
            <Text>Terms of Service</Text>
          </Link>
        </HStack>
      </HStack>
    </Box>
  );
};

export default Footer;
