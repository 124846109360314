import { createIcon } from "@chakra-ui/icons";

const FemalePurpleAvatar = createIcon({
  displayName: "FemalePurpleAvatar",
  viewBox: "0 0 227 227",
  path: (
    <>
      <path
        opacity=".5"
        d="M113.5 227c62.684 0 113.5-50.816 113.5-113.5S176.184 0 113.5 0 0 50.816 0 113.5 50.816 227 113.5 227Z"
        fill="url(#paint0_linear_41_1732)"
      />
      <path
        d="M113.499 223.983c61.359 0 111.101-49.741 111.101-111.1 0-61.36-49.742-111.1-111.101-111.1-61.36 0-111.1 49.74-111.1 111.1 0 61.359 49.74 111.1 111.1 111.1Z"
        fill="#364147"
      />
      <path
        d="M112.651 159.686c27.202 0 49.253-22.051 49.253-49.252 0-27.202-22.051-49.253-49.253-49.253-27.201 0-49.253 22.05-49.253 49.253 0 27.201 22.052 49.252 49.253 49.252Z"
        fill="#333"
      />
      <path
        opacity=".1"
        d="M95.344 155.104h34.366v27.492a17.177 17.177 0 0 1-34.357 0v-27.492h-.01Z"
        fill="#000"
      />
      <path
        d="M98.615 153.831h27.804c.871 0 1.705.345 2.32.961.616.615.961 1.449.961 2.319v24.211c0 4.556-1.81 8.925-5.031 12.147a17.181 17.181 0 0 1-24.294 0 17.18 17.18 0 0 1-5.031-12.147v-24.211a3.28 3.28 0 0 1 3.27-3.28Z"
        fill="#BE7C5E"
      />
      <path
        opacity=".1"
        d="M95.414 168.08a49.456 49.456 0 0 0 34.366.098v-4.221H95.414v4.123Z"
        fill="#000"
      />
      <path
        d="M112.651 170.381c27.202 0 49.253-22.052 49.253-49.253 0-27.202-22.051-49.253-49.253-49.253-27.201 0-49.253 22.051-49.253 49.253 0 27.201 22.052 49.253 49.253 49.253Z"
        fill="#BE7C5E"
      />
      <path
        d="M82.8 100.816c.578-2.125 1.302-4.466 3.212-5.573 1.508-.881 3.379-.744 5.122-.656 13.614.745 27.109-2.36 40.351-5.602 7.57-1.85 15.67-4.103 20.567-10.205 4.896-6.102-1.489-25.278-8.942-27.54-4.897-1.498-10.577 1.293-15.22-.901-2.066-.98-3.584-2.81-5.367-4.24-3.653-2.939-8.344-4.124-12.888-5.24-5.308-1.313-11.048-2.596-16.091-.49-3.918 1.626-6.778 5.063-10.49 7.1-7.06 3.918-15.67 2.096-23.76 2.077-2.732 0-5.993.548-7.08 3.055-.735 1.704-.147 3.644-.069 5.495.235 5.876-4.613 10.45-8.677 14.69-4.064 4.241-7.904 10.293-5.338 15.563.98 2.007 2.772 3.525 3.79 5.513 1.754 3.448.872 7.61 1.509 11.42a28.733 28.733 0 0 0 3.202 8.227c3.017 5.876 18.168 37.51 25.131 29.469 3.37-3.917 3.918-15.239 5.28-20.332 1.88-7.319 3.8-14.596 5.758-21.83Z"
        fill="#333"
      />
      <path
        d="M77.61 127.925c2.532 0 4.584-3.845 4.584-8.589 0-4.743-2.052-8.589-4.583-8.589-2.532 0-4.584 3.846-4.584 8.589 0 4.744 2.052 8.589 4.584 8.589Z"
        fill="#BE7C5E"
      />
      <path
        d="M113.71 223.867c61.359 0 111.1-49.741 111.1-111.1 0-61.36-49.741-111.101-111.1-111.101-61.36 0-111.1 49.741-111.1 111.101 0 61.359 49.74 111.1 111.1 111.1Z"
        fill="#080F16"
      />
      <circle
        cx="96.336"
        cy="62.585"
        r="18.215"
        fill="url(#paint1_linear_41_1732)"
      />
      <path
        d="M112.08 161.433a192.672 192.672 0 0 1-28.388-2.602c-18.696-3.124-37-9.092-53.605-18.292a393.67 393.67 0 0 1-2.97-1.68c14.723-13.975 25.65-30.883 34.886-48.819C68.654 77.1 72.099 68.565 86.465 63.12c24.98-9.46 58.725-.261 65.828 27.842 2.999 11.878 3.112 24.296 1.407 36.382-1.645 11.688-5.974 34.196-5.974 34.196s-30.984-.023-35.646-.106Z"
        fill="url(#paint2_linear_41_1732)"
      />
      <path
        d="M181.228 198.615c-39.701 30.568-95.076 30.324-134.506-.594l28.9-13.547a28.145 28.145 0 0 0 2.583-1.39l.665-.415c.238-.149.47-.303.7-.464a27.64 27.64 0 0 0 8.713-9.781c2.376-4.484 3.16-9.437 3.195-14.485 0-2.774-.112-5.547-.184-8.315 0-.621-.011-1.245-.035-1.871-.024-1.88-.044-3.763-.06-5.648h.042l1.134.594 3.088 1.598 17.99 3.059 18.482 3.147.421 14.254.119 4.086v.635l.279 9.425 6.812 2.774 5.345 2.162 36.317 14.776Z"
        fill="#F2A196"
      />
      <path
        d="M132.351 164.763a7.201 7.201 0 0 1-4.359 1.253c-8.778-.945-25.918-7.828-36.691-20.567 0-.622-.012-1.245-.036-1.871v-1.467l1.135.594 3.088 1.598 17.989 3.058 18.482 3.148.392 14.254Z"
        fill="#E88870"
      />
      <circle cx="76.346" cy="123.083" r="10.096" fill="#F2A196" />
      <circle cx="79.92" cy="121.895" r="10.096" fill="#E88870" />
      <path
        d="M144.968 107.796c6.972 24.16-7.84 53.374-16.95 53.374-12.472 0-42.76-9.966-49.727-34.126-6.966-24.159 2.322-48.058 20.733-53.367 18.411-5.31 38.972 9.953 45.944 34.119Z"
        fill="#F2A196"
      />
      <path
        d="M110.504 137.146a17.655 17.655 0 0 0 11.011 5.755M122.747 110.338c-.03.119 2.845 5.084 4.342 10.447a34.5 34.5 0 0 1 .962 8.766h-8.024M130.109 105.284a18.516 18.516 0 0 1 13.57-1.283M94.984 107.648a21.828 21.828 0 0 1 18.548-.594"
        stroke="#00214E"
        stroke-width=".5"
      />
      <path
        d="M105.401 112.909c3.622.03 3.622 5.571 0 5.595h-.161c-3.622 0-3.622-5.565 0-5.595h.161ZM135.17 112.084c3.361 0 3.361 5.167 0 5.191h-.149c-3.367 0-3.367-5.167 0-5.191h.149Z"
        fill="#00214E"
      />
      <path
        d="M181.228 198.615c-39.701 30.569-95.076 30.324-134.506-.594l28.9-13.547a27.705 27.705 0 0 0 3.949-2.268c.38-.262.76-.535 1.122-.82a27.517 27.517 0 0 0 7.59-8.962 26.538 26.538 0 0 0 2.756-8.599c.42.406.86.791 1.318 1.152a56.499 56.499 0 0 0 11.771 6.693 58.926 58.926 0 0 0 6.213 2.192 55.552 55.552 0 0 0 19.598 2.072c.915-.071 1.835-.16 2.75-.225l.089 3.017v.196l7.186 2.922 41.264 16.771Z"
        fill="#AC4CCC"
      />
      <path
        d="M117.937 76.5c-2.56 11.106-8.136 20.537-18.328 27.408-10.998 7.614-22.61 11.284-35.277 13.808.32-7.786-.534-18.737 3.48-25.68a47.202 47.202 0 0 1 19.19-18.03c8.795-4.544 22.401-8.998 32.283-5.85a67.62 67.62 0 0 1-1.348 8.344Z"
        fill="url(#paint3_linear_41_1732)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_41_1732"
          x1="113.5"
          y1="227"
          x2="113.5"
          y2=".01"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="gray" stop-opacity=".25" />
          <stop offset=".54" stop-color="gray" stop-opacity=".12" />
          <stop offset="1" stop-color="gray" stop-opacity=".1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_41_1732"
          x1="78.121"
          y1="80.803"
          x2="114.557"
          y2="80.803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#623B28" />
          <stop offset="1" stop-color="#8A5D48" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_41_1732"
          x1="105.692"
          y1="198.826"
          x2="159.964"
          y2="111.849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#361C10" />
          <stop offset="1" stop-color="#7C503C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_41_1732"
          x1="83.047"
          y1="134.953"
          x2="90.07"
          y2="74.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#402416" />
          <stop offset="1" stop-color="#7B503B" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default FemalePurpleAvatar;
