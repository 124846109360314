import {
  Box,
  Center,
  Stack,
  Text,
  HStack,
  Card,
  Badge,
} from "@chakra-ui/react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { aboutUsSlides, amountData } from "./staticData";
import FormVaultIcon from "../../icons/FormVaultIcon";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import StyledInput from "../StyledInput";
import StyledTextArea from "../StyledTextArea";
import StyledButton from "../StyledButton";
import PriceListIcon from "../../icons/PriceListIcon";
import Star from "../../icons/Start";
import ParkIcon from "../../icons/ParkIcon";
import { useResponsive } from "../../providers/useMediaProvider";

interface IFormInput {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  message: string;
}

const FinalLayout = () => {
  const { handleSubmit, control, reset } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    emailjs
      .send("service_5eec9wo", "template_tf0nu82", data as any, {
        publicKey: "4X97BksteCvVuWvbt",
      })
      .then(() => {
        reset();
      });
  };

  return (
    <Box background="#17192c">
      <Stack alignItems="center" paddingTop="7%">
        <Text
          color="white"
          fontSize={{ base: "25px", md: "64px" }}
          fontWeight={600}
        >
          <Text as="span" color="#995EF9">
            Get in touch
          </Text>{" "}
          with us
        </Text>
        {/* <Text color="#CDCAD7" fontSize={{ base: "17px", md: "22px" }}>
          Reach out, let’s create a positive change together
        </Text> */}
      </Stack>
      <Center p={{ base: "5% 1%", md: "5% 10%" }}>
        <Stack
          direction={{ base: "column", md: "row" }}
          width="95%"
          border="2px solid #0A0D170D"
          background="#FFFFFF0A"
          borderRadius="20px"
          padding={5}
        >
          <Stack
            flex="1"
            color="#FFFFFF"
            gap={10}
            padding={{ base: "20px 10px", md: "20px 30px" }}
          >
            <Text color="white" fontSize="30" fontWeight={600}>
              Have questions? <br /> Talk to us!
            </Text>
            <Text color="#FFFFFF">
              Reach out, let’s create a positive change together
            </Text>
            <Stack gap={3} as="form" onSubmit={handleSubmit(onSubmit)}>
              <HStack gap={3}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <StyledInput {...field} placeholder="First Name" />
                  )}
                />
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <StyledInput {...field} placeholder="Last Name" />
                  )}
                />
              </HStack>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <StyledInput {...field} placeholder="Email" />
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <StyledInput {...field} placeholder="Phone Number" />
                )}
              />
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <StyledTextArea {...field} placeholder="Message" />
                )}
              />
              <StyledButton type="submit">Send</StyledButton>
            </Stack>
          </Stack>
          <Center
            flex="1"
            background="linear-gradient(360deg, rgba(112, 243, 241, 0.1425) 20.4%, rgba(10, 13, 23, 0) 100%)"
            borderRadius="12px"
          >
            <FormVaultIcon
              fontSize={{ base: "250px", md: "440px" }}
              fill="none"
            />
          </Center>
        </Stack>
      </Center>
    </Box>
  );
};
const AboutUsContent = () => {
  const { isLargerThan768 } = useResponsive();

  return (
    <Stack
      height="100vh"
      background="white"
      justifyContent="flex-end"
      alignItems="center"
      className="fifth-fold-about-us"
      paddingX="10%"
      gap={10}
      marginTop={{ base: "2%", md: "5%" }}
    >
      {!isLargerThan768 && (
        <Text fontSize="17px" textAlign="center">
          At WALLT, we have curated features that aid in your journey of
          planning your legacy.
        </Text>
      )}
      <Box
        width={{ base: "355px", md: "1000px" }}
        className="fifth-fold-about-us"
      >
        <Carousel showArrows={false} showStatus={false} autoPlay>
          {aboutUsSlides.map((slide: any) => {
            return (
              <Stack
                paddingBottom={{ base: "20px", md: 50 }}
                spacing={8}
                color="#17192C"
              >
                <Text fontSize={{ base: "16px", md: "25px" }}>
                  {slide.content}
                </Text>
                <Text fontSize="16px">{slide.author}</Text>
              </Stack>
            );
          })}
        </Carousel>
      </Box>
    </Stack>
  );
};

const Pricing = () => {
  const pricingCOnRef = useRef(null);
  return (
    <Stack ref={pricingCOnRef}>
      <Stack color="white" alignItems="center" spacing={10}>
        <Stack
          padding={{ base: "7% 5%", md: "7% 25%" }}
          alignItems="center"
          gap={10}
        >
          <Text
            color="white"
            fontSize={{ base: "25px", md: "70px" }}
            fontWeight={600}
          >
            Plans & Pricing
          </Text>
          <Text
            fontSize={{ base: "16px", md: "22px" }}
            color="#CDCAD7"
            textAlign="center"
          >
            We have customised subscription plans to suit your needs on this
            end-of-life planning journey. Let WALLT start safeguarding your
            legacy.
          </Text>
        </Stack>
      </Stack>
      <Stack background="#17192c" alignItems="center">
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={10}
          width="80%"
          pb="15px"
          marginTop={{ md: "3%" }}
        >
          {amountData.map((item: any, number: any) => (
            <Stack
              as={Card}
              spacing={2}
              background="transparent"
              key={number}
              border="1px solid #FFFFFF29"
              flex="1"
              padding={5}
              color="#1BEBE7"
              borderRadius="25px"
              position="relative"
            >
              {item.tag && (
                <HStack
                  width="fit-content"
                  background="linear-gradient(90deg, #7A32F1 0%, #AF31C4 100%)"
                  borderRadius="10px"
                  position="absolute"
                  top="-12px"
                  right="25px"
                  padding="5px 10px"
                  color="white"
                >
                  <Star />
                  <Text>Most Popular</Text>
                </HStack>
              )}
              <HStack alignItems="flex-start">
                <Text fontSize="32px">{item.title}</Text>
                <Text fontSize="18px">{item.badge}</Text>
              </HStack>
              <Stack spacing={1} marginY={item.discount ? 0 : "14px"}>
                <HStack color="white" spacing={1}>
                  <Text fontSize="32px">&#8377;</Text>
                  <Text fontSize="46px">{item.price}</Text>
                  <Text fontSize="22px" color="#1BEBE7">
                    {item.duration}
                  </Text>
                </HStack>
                {item.discount && (
                  <HStack color="white" fontSize="14px" spacing={1}>
                    <Text as="del" color="#9089A8">
                      {item.discount.price}
                    </Text>
                    <Text>{item.discount.after}</Text>
                    <Text color="#1BEBE7">{item.discount.save}</Text>
                  </HStack>
                )}
                <Text
                  as="span"
                  fontSize="11px"
                  border="1px solid white"
                  borderRadius="6px"
                  color="white"
                  width="fit-content"
                  padding="2px 6px"
                >
                  Introductory offer
                </Text>
              </Stack>
              <StyledButton
                onClick={() => window.open("http://app.wallt.in/sign-up")}
                marginTop={3}
              >
                Get Started
              </StyledButton>
              {item.profits.map((list: string, index: number) => (
                <HStack key={index} color="#9089A8">
                  <PriceListIcon fill="none" fontSize="30px" />
                  <Text>
                    {list}
                    {index === 1 && (
                      <Badge
                        background="#7A32F1"
                        border="none"
                        color="white"
                        ml={1}
                        borderRadius="4px"
                        textTransform="capitalize"
                      >
                        Beta
                      </Badge>
                    )}
                  </Text>
                </HStack>
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const AboutUsFold = () => {
  const aboutUsFoldRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: aboutUsFoldRef,
  });
  const { isLargerThan768 } = useResponsive();

  const [circleStyle, setCircleStyle] = useState({
    borderRadius: "0%",
    alignItems: "center",
    display: "flex",
    background: "#17192c",
    height: window.innerWidth > 768 ? "150vh" : "120vh",
    transform:
      window.innerWidth > 800 ? "translate(-16.5%, 0)" : "translate(-25.5%, 0)",
    transition: "transform 300ms ease-in-out, border-radius 300ms ease-in-out",
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest > 0.23) {
      setCircleStyle({
        borderRadius: "0",
        alignItems: "flex-start",
        display: "flex",
        background: "#17192c",
        height: isLargerThan768 ? "55vh" : "30vh",
        transform: isLargerThan768
          ? `translate(-16.5%, 184%)`
          : "translate(-25.5%, 315%)",
        transition: "transform 300ms ease-in-out",
      });
    } else if (latest > 0) {
      setCircleStyle({
        borderRadius: "0 0 50% 50%",
        alignItems: "flex-end",
        display: "flex",
        background: "#17192c",
        height: isLargerThan768 ? "150vh" : "120vh",
        transform: isLargerThan768
          ? "translate(-16.5%, -65%)"
          : "translate(-25.5%, -65%)",
        transition: "transform 300ms ease-in-out",
      });
    } else {
      setCircleStyle({
        borderRadius: "0%",
        alignItems: "flex-start",
        display: "flex",
        background: "#17192c",
        height: isLargerThan768 ? "150vh" : "120vh",
        transform: isLargerThan768
          ? "translate(-16.5%, 0)"
          : "translate(-25.5%, 0)",
        transition: "transform 300ms ease-in-out",
      });
    }
  });

  const getContent = (isDefault = false) => {
    let content = <></>;
    if (scrollYProgress.get() > 0.23 && !isDefault) {
      content = (
        <Stack
          padding={{ base: "7% 5%", md: "7% 25%" }}
          alignItems="center"
          gap={10}
          width={{ base: "100vw", md: "auto" }}
        >
          <Text
            color="white"
            fontSize={{ base: "25px", md: "70px" }}
            fontWeight={600}
          >
            Plans & Pricing
          </Text>
          <Text
            fontSize={{ base: "16px", md: "22px" }}
            color="#CDCAD7"
            textAlign="center"
          >
            We have customised subscription plans to suit your needs on this
            end-of-life planning journey. Let WALLT start safeguarding your
            legacy.
          </Text>
        </Stack>
      );
    } else {
      content = (
        <Stack
          paddingTop="200px"
          paddingBottom="170px"
          color="white"
          alignItems="center"
          spacing={10}
          width={{ base: "80vw", md: "auto" }}
        >
          <Box>
            <Text
              as="h1"
              fontWeight={600}
              fontSize={{ base: "25px", md: "50px" }}
              color="white"
              textAlign="center"
            >
              See what our users are saying about us
            </Text>
            {isLargerThan768 && (
              <Text fontSize="22px" textAlign="center">
                At WALLT, we have curated features that aid in your journey of
                planning your legacy.
              </Text>
            )}
          </Box>
        </Stack>
      );
    }
    return content;
  };

  return (
    <Box ref={aboutUsFoldRef} position="relative" overflow="hidden">
      <Center
        width={{ base: "200%", md: "145%" }}
        sx={{
          borderRadius: "0 0 50% 50%",
          alignItems: "flex-end",
          display: "flex",
          background: "#17192c",
          height: isLargerThan768 ? "150vh" : "120vh",
          transform: isLargerThan768
            ? "translate(-16.5%, -65%)"
            : "translate(-25.5%, -65%)",
          backgroundImage: "var(--polka-bg-image)",
          backgroundPosition: "var(--polka-bg-position)",
          backgroundSize: "var(--polka-bg-size)",
        }}
        position="absolute"
        zIndex={99}
      >
        {getContent(true)}
      </Center>
      <Center
        width={{ base: "200%", md: "145%" }}
        sx={{
          ...circleStyle,
          backgroundImage: "var(--polka-bg-image)",
          backgroundPosition: "var(--polka-bg-position)",
          backgroundSize: "var(--polka-bg-size)",
        }}
        position="absolute"
        zIndex={99}
      >
        {getContent()}
      </Center>
      <ParkIcon
        position="absolute"
        fill="none"
        fontSize={{ base: "180px", md: "520px" }}
        color="#35D1C1"
        zIndex={999}
        top={{ base: "10%", md: "5%" }}
      />
      <AboutUsContent />
      <Pricing />
      <FinalLayout />
    </Box>
  );
};

export default AboutUsFold;
