import { createIcon } from "@chakra-ui/icons";

const FormVaultIcon = createIcon({
  displayName: "FormVaultIcon",
  viewBox: "0 0 438 377",
  path: (
    <>
      <g opacity=".7">
        <path
          d="m7.47 219.907-.666-2.383c-1.51.264-4.558.775-4.662.794-1.466.265-1.2 1.677-.933 2.516l8.26 33.894c.213 2.012 1.598 2.074 2.264 1.853l4.397-.794-1.6-.133c-.958.212-1.464-.706-1.598-1.191l-3.996-34.556H7.47Z"
          fill="#C8E8FE"
          stroke="#D9D9D9"
        />
        <path
          d="M7.41 219.925h1.755c0-2.692-.35-7.994-1.755-7.671-1.605.539-.669 5.338 0 7.671Z"
          fill="#8DFCFA"
        />
        <path
          d="M9.978 212c-3.066 0-2.987.348-2.855.48.74-.317.785 1.193 1.005 2.03 1.498 12.99 4.52 39.076 4.625 39.499.952 2.642 1.542 1.1 1.718 0V247.8h27.354c-1.233-10.084-3.726-30.807-3.832-33.026-.105-2.219-1.19-2.774-1.718-2.774H9.978Z"
          fill="#16BCB9"
        />
        <path
          d="M14.519 253.487c-.106 1.804-.572 2.078-.792 1.989.792.531 27.964.531 29.415.398 1.161-.106 1.363-1.194 1.32-1.724l-.092-4.409a2 2 0 0 0-2-1.958H14.52c.044 1.149.105 3.9 0 5.704Z"
          fill="#8DFCFA"
        />
        <rect
          x="11.806"
          y="216.083"
          width="11.767"
          height="1.681"
          rx=".841"
          fill="#D9D9D9"
        />
        <rect
          x="12.194"
          y="221.471"
          width="13.489"
          height="2.009"
          rx="1.005"
          fill="#D9D9D9"
        />
        <rect
          x="13.489"
          y="232.893"
          width="16.57"
          height="2.161"
          rx="1.081"
          fill="#D9D9D9"
        />
        <rect
          x="14.208"
          y="238.177"
          width="9.366"
          height="2.161"
          rx="1.081"
          fill="#D9D9D9"
        />
        <rect
          x="13.006"
          y="227.13"
          width="24.736"
          height="2.402"
          rx="1.201"
          fill="#D9D9D9"
        />
        <rect
          x="27.692"
          y="221.366"
          width="7.685"
          height="2.161"
          rx="1.081"
          fill="#D9D9D9"
        />
        <path
          d="m48.555 247.529-7.993-5.377-.638-.319.958-2.074 8.723 6.358c.44.321.857.844.639 1.343a.72.72 0 0 1-.092.154c-.366.471-1.102.248-1.597-.085Z"
          fill="#401970"
        />
        <path
          d="m35.044 232.302-3.51 7.179 8.136 4.307 3.19-7.019-.478-2.393-2.393-1.436-4.945-.638Z"
          fill="#AC4CCC"
        />
        <path
          d="M36.21 232.995c-1.404-.256-1.806.318-1.806.743-.372-.744-.553-2.472.978-2.727 5.424-.478 8.435 3.525 8.754 4.961.255 1.148-.957 1.648-1.596 1.755.639-2.872-4.575-4.413-6.33-4.732ZM40.144 245.183c-.793 1.118-3.495.524-6.217-.956-3.032-1.437-4.68-3.293-3.83-4.467.798-.638 2.55-.778 5.74.956 3.833 1.756 4.865 3.442 4.307 4.467Z"
          fill="#7D28B3"
        />
      </g>
      <g opacity=".7">
        <path
          d="m218.613 20.987-5.281.47 7.68.015 3.999-.152-6.398-.333Z"
          fill="#BAF8F6"
        />
        <path
          d="m249.151 10.344 4.477-8.258A.929.929 0 0 0 253.371.9l-7.062 10.643a2.47 2.47 0 0 0 2.842-1.2ZM244.713 10.003l.798.802-16.645 11.035a.998.998 0 0 1-.596.166l-1.5-.066 17.943-11.937ZM218.886 13.969l-.835-.99-9.387 8.187c-.577.503-.222 1.453.543 1.454l9.679-8.651Z"
          fill="#73298B"
        />
        <path
          d="m221.869 5.911 5.766 7.087-1.371.986-7.817-8.612c1.584-.788 2.941.031 3.422.54Z"
          fill="#DEB5F6"
        />
        <path
          d="m213.472 28.106-2.892.45-.032 15.594 2.892-.755.032-15.29ZM235.725 23.103l-3.777.472-.048 23.432 3.807-1.741.018-22.163ZM225.011 21.321l-4 .152-.137 24.066 4.091-1.82.046-22.398Z"
          fill="#16BCB9"
        />
        <path
          d="m210.58 28.555-6.541-.393 4.109-.753 6.769.47-4.337.676Z"
          fill="#BAF8F6"
        />
        <path
          d="m204.039 28.163 6.541.393-.032 15.594-6.54-.926.031-15.061ZM223.962 23.563l7.987.017-.048 23.428-7.985-1.005.046-22.44Z"
          fill="#70F3F1"
        />
        <path
          d="m212.773 9.81-6.331 5.972a4.433 4.433 0 0 0-.935 5.183 2.216 2.216 0 0 0 3.524.619l9.029-8.678 2.312 2.86a10 10 0 0 0 13.457 1.944l10.973-7.574c.783 1.353 2.743 1.333 3.498-.036L253.37.9 243.606.29c-1.572-.098-2.689 1.503-2.054 2.944l-11.634 8.189a3.992 3.992 0 0 1-5.421-.778c-2.911-3.656-8.325-4.041-11.724-.835Z"
          fill="#AC4CCC"
        />
        <path
          d="m213.332 21.456 7.679.016-.049 23.997-7.518-.694-.112-23.319Z"
          fill="#70F3F1"
        />
        <path
          d="m227.31 22.885-3.348.678 7.987.016 3.777-.476-8.416-.218Z"
          fill="#BAF8F6"
        />
      </g>
      <g opacity=".7">
        <path
          d="m31.848 71.64-13.075 5.21 30.753-3.402 13.599-5.53-31.277 3.722Z"
          fill="#33007D"
        />
        <path
          d="M28.494 75.077c-1.742-.339-5.565.564-7.258 1.058l1.866 17.04h3.421l5.185-1.482c-1.118-5.546-3.325-16.635-3.214-16.616Z"
          fill="#1BEBE7"
        />
        <path
          d="M23.65 92.904c-1.102.085-1.237 1.447-1.166 2.118h4.133c1.766-1.13 5.255-3.388 5.086-3.388-.212 0-7.1.741-8.053 1.27Z"
          fill="#88BFFF"
        />
        <path
          d="M50.362 72.69c-2.157-.494-6.22.31-7.258 1.133l1.867 15.964h4.665l3.94-1.236-3.214-15.86ZM60.525 68.842c-1.762 0-6.843.522-7.258 1.044l1.867 16.815 4.562.313 4.043-1.775-3.214-16.397Z"
          fill="#1BEBE7"
        />
        <path
          d="M55.233 86.657c-.586.167-.732 1.396-.732 1.99l5.233.524 5.547-1.362c-.14-.803-.628-2.451-1.466-2.619-1.046-.21-7.85 1.047-8.582 1.467Z"
          fill="#88BFFF"
        />
        <path
          d="M38.862 71.352c-2.488-.25-5.806.59-7.154 1.041l1.866 16.669 4.355.416 4.251-1.25-3.318-16.876Z"
          fill="#1BEBE7"
        />
        <path
          d="M33.316 89.176c-.757.328-.736 1.639-.631 2.254l6.205.204 4.522-1.639c-.035-.649-.358-1.967-1.367-2.049-1.262-.102-7.783.82-8.73 1.23Z"
          fill="#88BFFF"
        />
        <path
          d="M32.407 97.565c.145-.058.299-.099.452-.122 13.823-2.058 22.944-3.342 29.929-3.972 2.159-.195 2.72 2.382.669 3.086-2.09.718-3.808 1.182-5.832 1.668-18.996 2.584-30.343 4.361-35.335 4.732-1.427.107-1.495-.743-.166-1.274l10.283-4.118Z"
          fill="#73298B"
        />
        <path
          d="M21.177 95.39c-1.257.418-.873 1.778-.524 2.405L67.69 89.22c.42-2.176-1.676-1.883-2.618-1.883-.943 0-21.092 2.44-31.114 3.87-1.508.167-3.282.697-3.98.941-2.41.907-7.543 2.824-8.8 3.242Z"
          fill="#33007D"
        />
        <path
          d="m29.012 94.45-9.933 3.976.836 4.604 12.443-4.604c7.284-.872 23.128-2.826 28.23-3.663 5.102-.837 8.539-.698 9.62-.523-.175-.942-.565-3.118-.733-4.29-.167-1.172-4.67-.768-6.9-.419-7.006.872-21.937 2.72-25.617 3.14-3.68.418-6.83 1.36-7.946 1.778Z"
          fill="#9A30CE"
        />
        <path
          d="m64.34 62.798-18.804-9.356-13.972 9.356-2.627 7.276 18.175-1.455 15.863-2.806c.21-.035.777-.188 1.366-.52.588-.333.245-1.802 0-2.495Z"
          fill="#1BEBE7"
        />
        <path
          d="M16.306 74.135c.334.832 1.597.555 2.187.313l13.017-4.992c-1.25-.582-1.076-1.906-.833-2.496l14.891-13.518-14.891 5.72c-4.34 4.02-13.204 12.145-13.954 12.478-.937.416-.833 1.456-.417 2.495Z"
          fill="#0DA09E"
        />
        <path
          d="m18.463 74.611.418 2.239 13.074-5.224 31.169-3.732-.314-2.132-31.273 3.731-13.074 5.118Z"
          fill="#9A30CE"
        />
        <path
          d="M50.497 61.201c0 2.577-1.89 4.562-4.1 4.562-2.209 0-4.2-1.985-4.2-4.562-.2-2.592 1.4-4.873 4-4.665 2.209 0 4.3 2.088 4.3 4.665Z"
          fill="#5015A5"
        />
        <path
          d="M51.422 60.892c0 2.577-1.96 4.562-4.251 4.562-2.291 0-4.356-1.985-4.356-4.561-.207-2.592 1.452-4.873 4.148-4.666 2.29 0 4.459 2.089 4.459 4.666Z"
          fill="#9A30CE"
        />
        <path
          d="M49.572 60.772c0 1.977-1.327 3.143-2.713 3.143-1.386 0-2.823-1.36-2.823-3.075-.125-1.726 1.192-3.214 2.823-3.076 1.386 0 2.713 1.293 2.713 3.008Z"
          fill="#5B0F80"
        />
      </g>
      <g opacity=".7">
        <path
          d="m378.441 63.861 10.211.753c1.077.08 2.135.331 3.133.745l.273.113a7.178 7.178 0 0 1-1.52 13.703l-1.016.177a5.656 5.656 0 0 1-1.348.07l-10.779-.724a7.437 7.437 0 1 1 1.046-14.837Z"
          fill="url(#paint0_linear_960_1084)"
        />
        <path
          d="M384.56 104.744c.187 1.243-1.357 1.216-1.823 1.087l.037-2.174 2.951-3.029 5.282-4.117.311.155c-.725 1.088-2.02 3.03-2.797 3.03-1.941.04-1.32.776-1.553 2.252-.233 1.476-2.253.466-2.486.854-.233.389-.155.389.078 1.942Z"
          fill="#0DA7A4"
        />
        <path
          d="m394.347 96.821-.155-1.242 6.524-9.632-4.039-7.145c2.175 0 6.82 1.18 8.001 5.903 1.18 4.722-1.165 8.492-2.486 9.786v1.398a.932.932 0 0 1-.932.932l.388 4.195-.399.442a1.925 1.925 0 0 0-.102 2.463l.257.336a1.453 1.453 0 0 1-.147 1.93 1.452 1.452 0 0 0-.004 2.09l.253.246c.688.667.73 1.757.096 2.475l-1.818 2.057-1.725-1.596a5.002 5.002 0 0 1-1.578-3.15l-1.202-11.488h-.932Z"
          fill="#177F7D"
        />
        <path
          d="M396.911 78.562c-3.977-6.897-6.111-5.307-6.68-3.65.31-3.65 4.039-3.257 5.903-.927 1.491 1.864 2.149 4.116 2.252 4.97l-1.475-.393Z"
          fill="#3A1347"
        />
        <path
          d="M388.677 82.113c2.423-4.287 6.965-3.806 8.932-3.03 2.641 1.244 5.049 3.885 5.049 7.535-.062 8.45-6.033 9.476-9.01 8.933l-1.101 1.05a1.05 1.05 0 0 1-1.307.115l-1.962 2.342c-.283.337-.7.531-1.139.531-.722 0-1.339.519-1.464 1.23l-.145.829a1.189 1.189 0 0 1-1.245.982 1.189 1.189 0 0 0-1.255 1.327l.034.287a1.43 1.43 0 0 1-1.384 1.599l-2.625.066-.13-2.32a5 5 0 0 1 .996-3.286l5.969-7.937c-.365-.639-.206-1.424.158-2.064.496-.872.442-1.633.309-1.975-.285-1.036-.42-3.728 1.32-6.214Z"
          fill="#1BEBE7"
        />
        <ellipse cx="396.677" cy="85.094" rx="1.786" ry="2.33" fill="#151326" />
        <path
          d="M395.435 83.85c-3.977-1.304-5.075-6.291-5.126-8.621 0-.88.403-2.688 2.019-2.874 3.791-.249 5.567 4.298 5.981 6.602l1.32.31c-.248-1.304-1.657-3.546-2.33-4.504-.958-1.32-3.588-3.868-6.447-3.495-2.858.372-3.21 3.831-3.029 5.514.052 1.295.886 4.645 3.806 7.69 2.92 3.045 5.636 2.338 6.698 1.587.165-.77.201-1.173.029-1.69a.938.938 0 0 0-.072-.16c-.201-.356-.951-.301-1.346-.199-.544.141-1.222-.066-1.503-.16Z"
          fill="#5B2C9F"
        />
        <path
          d="m379.978 103.58 7.612-10.33.273.252-7.534 10.175-.351-.097Z"
          fill="#242424"
          fill-opacity=".3"
        />
        <path
          d="m386.269 91.27.622 1.087c.621 1.243 3.573 4.039 5.126 4.816l1.631-1.631c-1.087-1.71-6.369-5.904-6.447-5.515-.062.31-.673.984-.932 1.243Z"
          fill="url(#paint1_linear_960_1084)"
        />
        <path
          d="M394.873 77.311c1.014-.634 1.531-3.018 1.584-4.18l-2.693-1.426c.126 1.141-.476 1.954-.793 2.218l1.902 3.388Z"
          fill="#81C9F2"
        />
        <path
          d="m377.092 69.504.347 1.392 1.431.1-1.216.76.347 1.393-1.099-.922-1.217.76.538-1.33-1.1-.922 1.432.1.537-1.33ZM381.856 69.799l.303 1.402 1.428.144-1.24.722.304 1.403-1.07-.956-1.24.722.579-1.313-1.07-.956 1.427.145.579-1.313ZM386.427 70.047l.367 1.387 1.432.08-1.206.777.367 1.387-1.112-.906-1.206.777.519-1.338-1.112-.906 1.432.08.519-1.338Z"
          fill="#5B2C9F"
        />
      </g>
      <g opacity=".7">
        <path
          d="m384.947 253.629 26.742 3.958 2.326-3.531 1.027-21.175-16.08-24.225-15.557 17.613.218 3.479c-.23 4.372-.805 14.078-1.26 17.926-.455 3.847 1.533 5.573 2.584 5.955Z"
          fill="#70F3F1"
        />
        <path
          d="m404.167 239.737-.873 12.41-.95.395-12.117-1.776c.309-3.288.924-10.512.921-13.099-.003-2.587 2.469-3.368 3.706-3.435 2.068.146 6.425.591 7.305 1.207 1.577 1.219 1.996 3.373 2.008 4.298Z"
          fill="#5B2C9F"
        />
        <path
          d="m427.636 247.571.965-13.737-14.715-7.501-2.197 31.254 7.066-.778a10 10 0 0 0 8.881-9.238Z"
          fill="#16BCB9"
        />
        <path
          d="m377.647 229.329 6.008-.039c-.055-1.852.762-3.18 1.177-3.612l14.085-13.098-1.25-1.935-20.02 18.684Z"
          fill="#0DA09E"
        />
        <path
          d="m432.901 233.842-18.512 1.763a3.467 3.467 0 0 0-.089-3.914l-17.128-23.855 20.466-2.025 17.206 23.729a2.718 2.718 0 0 1-1.943 4.302Z"
          fill="#AC4CCC"
        />
        <path
          d="m422.535 211.7-2.165 1.234-.743 10.571.427.492 2.79-.266.792-11.261-1.101-.77Z"
          fill="#16DBD7"
        />
        <path
          d="m420.386 212.702-.759 10.801-4.422-6.085.372-5.285 2.576-.512 2.233 1.081Z"
          fill="#70F3F1"
        />
        <path
          d="m393.539 210.463-15.571 14.761a3.073 3.073 0 0 0-.32 4.106l20.02-18.685 16.983 24.519a3.253 3.253 0 0 0 .382-4.204l-13.972-19.755a5 5 0 0 0-7.522-.742Z"
          fill="#5B2C9F"
        />
        <path
          d="m425.503 208.905-4.398.152-.275 3.907 4.431-.612.242-3.447Z"
          fill="#0DA09E"
        />
        <path
          d="m414.226 208.343 6.879.702-.275 3.919-6.864-.92.26-3.701Z"
          fill="#16DBD7"
        />
        <path
          d="m419.315 208.241-5.089.104 6.879.715 4.398-.153-6.188-.666Z"
          fill="#70F3F1"
        />
      </g>
      <path
        d="M112.891 314.949v-8.638l20.657 12.018v9.39l-20.657-12.77ZM198.902 364.149v-8.638l20.657 12.018v9.389l-20.657-12.769ZM334.483 308.188l-21.032 12.394v8.638l21.032-11.267v-9.765Z"
        fill="#88BFFF"
      />
      <path
        d="m141.06 322.836-7.511 4.882v-9.389l7.511 4.507ZM226.694 372.036l-7.511 4.882v-9.389l7.511 4.507ZM313.636 328.966l-8.073-3.883 8.023-4.877.05 8.76Z"
        fill="#C8E8FE"
      />
      <path
        opacity=".9"
        d="M329.295 108.759 252.27 66.697a80 80 0 0 0-76.001-.369l-70.065 37.383 108.165 62.886c20.281 11.268 17.652 25.164 17.652 35.68v166.755l106.663-63.472c.25-50.828.901-156.314 1.502-171.637.601-15.324-7.01-23.161-10.891-25.164Z"
        fill="url(#paint2_linear_960_1084)"
      />
      <path
        opacity=".56"
        d="M106.955 104.252c-6.61-.901-7.761 7.637-7.511 12.018-.125 55.585-.376 167.056-.376 168.257-.75 10.892 4.883 16.526 8.263 18.779l119.057 69.106 5.633-3.005V200.774c2.186-20.656-8.399-28.543-15.091-32.674l-54.834-31.924-55.141-31.924Z"
        fill="url(#paint3_linear_960_1084)"
        stroke="#1BEBE7"
      />
      <path
        d="m214.476 183.937-93.283-53.257c-3.333-1.903-7.479.504-7.479 4.343v152.968c0 1.805.973 3.469 2.545 4.355l93.283 52.578c3.333 1.879 7.455-.53 7.455-4.356V188.279a4.998 4.998 0 0 0-2.521-4.342Z"
        stroke="#1BEBE7"
      />
      <path
        d="M208.784 234.76c-.46-1.501-1.122-2.368-1.855-2.81-2.521-1.515-4.272 2.511-4.272 5.453v7.382c0 1.24.181 2.499.8 3.573.485.843.998 1.434 1.511 1.833 2.239 1.738 4.074-1.768 4.074-4.603v-8.777c0-.692-.055-1.389-.258-2.051Z"
        fill="#2E0640"
      />
      <ellipse
        cx="160.779"
        cy="189.506"
        rx="26.081"
        ry="16.378"
        transform="rotate(57.558 160.779 189.506)"
        fill="#835BAE"
      />
      <path
        d="m149.317 173.766-.2.094a10.526 10.526 0 0 0-3.576 16.299l13.511 16.073a7.994 7.994 0 0 0 9.45 2.123c1.493-.684 2.791-1.793 3.41-3.314 4.958-12.188-4.462-28.087-16.596-31.93-1.987-.629-4.113-.232-5.999.655Z"
        fill="#B97DCB"
      />
      <path
        d="M161.699 211.54c-2.515 1.599-5.984 1.343-9.642-.546-3.645-1.884-7.381-5.35-10.317-9.969-2.936-4.618-4.489-9.473-4.647-13.573-.158-4.114 1.083-7.363 3.598-8.962 2.515-1.598 5.984-1.343 9.641.547 3.646 1.883 7.382 5.35 10.318 9.968 2.936 4.619 4.488 9.473 4.646 13.573.159 4.114-1.082 7.363-3.597 8.962Z"
        fill="#401970"
        stroke="#1BEBE7"
      />
      <path
        d="m150.915 194.964.002-.001a.713.713 0 0 0 .137-.193l.002-.004-.141.198Zm.237-7.273a.734.734 0 0 0-.725-.048l-4.78 2.463-1.744 2.445.007-.001-.002.003-.281 4.933a.724.724 0 0 0 .325.65.725.725 0 0 0 .724.05h.002l1.534-.749.183-3.217 1.89-2.772.096-.046 3.005-1.467.091-1.593c-.001-.046-.017-.441-.325-.65v-.001Z"
        fill="#1BEBE7"
      />
      <path
        d="M145.753 191.65a.73.73 0 0 1-.446.588l-1.397.311-.007.001 1.743-2.445.107 1.545Z"
        fill="#0DA09E"
      />
      <path
        d="m154.375 190.15-.001.001-.123 2.164-3.006 1.466-1.871 2.632-.171 2.786-1.629.795h-.001a.725.725 0 0 1-.724-.049.727.727 0 0 1-.325-.651l.281-4.933.004-.005-.009.002 1.765-2.583 4.784-2.335a.722.722 0 0 1 .701.059c.218.145.34.388.325.65v.001Z"
        fill="#1BEBE7"
      />
      <path
        d="m157.315 192.624-.001.001-.35 6.149a.732.732 0 0 1-.409.615l-6.021 2.94-.003.001a.726.726 0 0 1-.723-.051.724.724 0 0 1-.325-.65l.301-5.066-.004-.001.004-.005v-.001l1.739-2.447 4.736-2.311.007-.001.024-.014a.723.723 0 0 1 .719.051c.219.145.321.528.306.789v.001Z"
        fill="#1BEBE7"
      />
      <path
        d="M149.781 196.564h.003l.001-.006-.004.006Zm1.735-2.446-.171.242-.288.405-.14.199-.002.002-.299.422.302-.426.137-.193.29-.407.172-.243-.001-.001Zm-.901 1.27-.079.111.079-.111Z"
        fill="#1BEBE7"
      />
      <path
        d="M148.602 193.33a.729.729 0 0 1-.446.589l-1.348.438-.009.002 1.765-2.584.037 1.555h.001ZM151.664 196.172c-.157.212-.443.254-.705.279l-1.173.112h-.003l.004-.005 1.738-2.45.031.238.183 1.107c.041.246.073.517-.075.718v.001Z"
        fill="#0DA09E"
      />
      <defs>
        <linearGradient
          id="paint0_linear_960_1084"
          x1="383.308"
          y1="65.527"
          x2="382.832"
          y2="79.468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7DC8F4" />
          <stop offset=".644" stop-color="#84CAF0" />
          <stop offset="1" stop-color="#379DD7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_960_1084"
          x1="388.089"
          y1="90.023"
          x2="382.507"
          y2="94.614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1BEBE7" />
          <stop offset="1" stop-color="#15CAC7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_960_1084"
          x1="30.946"
          y1="21.57"
          x2="564.034"
          y2="350.347"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stop-color="#FDD2FD" />
          <stop offset=".23" stop-color="#E87AF5" />
          <stop offset=".37" stop-color="#9A30CE" />
          <stop offset=".46" stop-color="#7E26BE" />
          <stop offset=".55" stop-color="#651DB1" />
          <stop offset=".63" stop-color="#5015A5" />
          <stop offset=".75" stop-color="#390D98" />
          <stop offset=".82" stop-color="#310B94" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_960_1084"
          x1="56.128"
          y1="83.901"
          x2="411.671"
          y2="234.342"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stop-color="#FDD2FD" />
          <stop offset=".23" stop-color="#E87AF5" />
          <stop offset=".37" stop-color="#9A30CE" />
          <stop offset=".46" stop-color="#7E26BE" />
          <stop offset=".55" stop-color="#651DB1" />
          <stop offset=".63" stop-color="#5015A5" />
          <stop offset=".75" stop-color="#390D98" />
          <stop offset=".82" stop-color="#310B94" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default FormVaultIcon;
