export const PrivacyPolicyStart = {
  title:"Privacy Policy",
  content:`This Privacy Policy ("Policy") governs the collection, use, and disclosure of personal information by WALLT [an online platform owned and operated by Astaea Technologies Private Limited] ("we," "our," or "us") when you ("user" or "you") access or use our online platform, WALLT (“Platform/Website”).`
}
export const PrivacyPolicyBody = [
  {
    title:"We care for your privacy!!",
    content:[
      `Therefore, this Policy is designed to inform you about how we collect, use, and handle your personal data when you use our websites, software, and services (“Services”) and to comply with the applicable laws and regulations, including the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 under the Information Technology Act, 2000, and any other relevant Indian laws concerning data protection and privacy.`,
      `All the information provided by you to us, including sensitive personal information, is voluntary. You have the right to withdraw your consent at any time, but please note that withdrawal of consent will not be retroactive. You can access, modify, correct and eliminate the data about you, which has been collected pursuant to your decision to become a user of the Platform except for user id/email which is unique and may only be updated once you have authenticated a new email id. If you update any identifier information relating to you, we may keep a copy of the information which you originally provided to us in our archives.`,
      `This policy only applies to our Website. In case you leave our Website, via a link or otherwise, you will be subject to the policy of the other website. We have no control over that policy or the terms of that website and you should check their policy before continuing to access such other website. These other websites may place their own cookies or other files on your computer, collect data or solicit personal information from you, for which we are not responsible or shall be held liable. Accordingly, we do not make any representations or take any responsibility concerning the privacy practices or policies of such third parties or terms of use of such websites, nor do we guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such websites. Our Website may enable you to communicate with other users or to post information to be accessed by others, whereupon other users may collect such data. We hereby expressly disclaim any liability for any misuse of such information that is made available by you to any other website in such a manner.`,
    ]
  },
  {
    title: "Information We Collect",
    content:
      [
        `Personal information is information that can be used to identify or contact you individually, and other information that we need to provide you with the Services you have requested. We collect and process various types of information, including personal information, to provide and improve our Services. The information we collect may include but is not limited to:`,
        `Personal information: such as your name, email address, phone number, government issued identification document and other contact details provided by you (including your address), either during registration or during your use of the Platform.`,
        `Document information: including file names, types, sizes, timestamps and any description that you choose to add along with the file.
        Miscellaneous information: including any information that may be required to successfully call an API which the user has requested while using the Platform.`,
        `Usage data: such as IP addresses, device information, browser type, and operating system.`,
        `Cookies and similar tracking technologies: we use limited cookies to track a login session. These cookies are used to enhance user experience and for analytics purposes. You can manage your cookie preferences through your browser settings.`,
        `WE DO NOT ACCESS THE CONTENT OF YOUR FILES/INFORMATION UPLOADED ON WALLT. THE FILES/INFORMATION STORED ON WALLT REMAIN END-TO-END ENCRYPTED.`,
      ]
  },
  {
    title:"How We Use Your Information",
    content:
      [
        `You have a right to know how your information will be used. We will explain why we need your information before or when we collect it, if the purpose is not already clear. We will assume that the purpose is clearly identified when we use your personal information to:`,
        [
          `Providing, maintaining, and improving the Services you have requested and to market, study, analyse, process and improve our Services;`,
          `Personalizing your experience and customizing Platform content and features and to contact you with constant updates to our Website;`,
          `Process your payment, including recurring payments as you request ;`,
          `Communicate with you or your nominees about the Services you have subscribed to;`,
          `Ensure that you are properly registered to use our Services and to receive any associated technical support;`,
          `Inform you of any important revisions or updates to our Services;`,
          `Analyzing usage trends and gathering statistical information to enhance performance and security; and`,
          `Complying with legal obligations and enforcing our terms of service.`,
        ],
        `We will also collect log information that includes your IP address and the time that you access our Platform or use our Service. This information is stored in log files and is used for security, to improve performance and for aggregated and statistical reporting. This information is displayed to an account holder for security purposes.`,
        `We may also use your personal information for secondary purposes such as:`,
        [
          `Letting you know about new or upgraded Services; and`,
          `Inviting you to participate in surveys or other opinion-gathering devices.`,
        ],
        `Aggregate data is general information about groups of customers and does not identify individual customers. We may combine your information with that  of other customers to generate aggregate data that can be used to improve our Services or develop new ones. For example, we may tell a third party how  many users have subscribed to a particular service, but not identify that you personally are a subscriber.`,
        `We will not disclose your personal information to any third parties without your consent, except under the following limited circumstances:`,
        [
          `As is reasonably required to fulfill your Service requirements, provided such third parties shall be bound to appropriate privacy requirements;`,
          `To comply with a legal process such as a law, regulation, search warrant, summons, court order or law enforcement request (please note that even in case of such obligations, which will be resisted by appropriate legal options, WALLT can only submit encrypted data as all data stored on WALLT is end-to-end encrypted);`,
          `To resolve a contractual dispute with us regarding your use of any of our Services;`,
          `To resolve issues related to the use of your account on the Platform for illegal activities; or`,
          `To protect the personal safety of other users or members of the public in an emergency.`
        ],
      ],
  },
  {
    title:"Disclosure of Information",
    content:
      [
        `We will not use or disclose your personal information other than for the purposes for which it was collected unless we receive your consent or unless we are required by law. The content of the files that you store for availing our Services is encrypted and only You or your nominee(s) on WALLT can decrypt it once access is shared with such nominee(s). When providing information in response to a legal inquiry or order, we will verify its validity and disclose only the information that is legally required. We will make reasonable efforts, within the bounds of the law, to notify you, should your personal information be subject to disclosure. Please note that even in case of such obligations, which will be resisted by appropriate legal options, WALLT can only submit encrypted data to government authorities as all data stored on WALLT is end-to-end encrypted.`,
        `We will keep your personal information only for as long as it needs to for the purposes identified above, as required by law, or as necessary to resolve any disputes you may have concerning our Services. We will follow internally set guidelines and use care in the disposal, destruction, or de-identification of personal information to prevent unauthorized parties from gaining access to such information.`,
        `In case you do not renew your subscription, and do not wish to resubscribe, within 6 months of the expiry of your earlier subscription, WALLT will delete all of your stored data. You can download all of your data stored on WALLT before the expiry of 6 months from the last active subscription.`,
        `However, we may share your personal information in the following circumstances:`,
        [
          `With service providers, vendors, or business partners who assist in providing the Services on the Platform.`,
          `In response to legal requests or obligations, such as court orders or government regulations (subject to the exceptions provided above).`,
          `In connection with mergers, acquisitions, or asset sales, where personal information may be transferred.`,
          `With your consent or as otherwise permitted or required by law.`
        ]
      ]
  },
  {
    title:"Data Security",
    content:
      [
        `We implement appropriate technical and organizational measures to protect the security and confidentiality of your personal information.`,
        `We keep your information for as long as your account is active or as needed. However, we may keep certain information even after you close your account if it is necessary to comply with our legal obligations, meet regulatory requirements, resolve disputes, prevent fraud and abuse, or enforce this Agreement or for audit purposes.`,
      ]
  },
  {
    title:"Your Rights",
    content:
      [
        `You have certain rights regarding your personal information, including the right to access, correct, or delete information we hold about you. You may also have the right to object to or restrict certain processing activities. To exercise your rights or for any inquiries, please contact us using the details provided below.`
      ]
  },
  {
    title:"Changes to this Policy",
    content:
      [
        `We reserve the right to update or modify this Privacy Policy at any time without prior notice, in accordance with changes in applicable law or internal guidelines. Any changes to the Policy will be effective immediately upon posting on the Platform. We encourage you to review this Policy periodically for updates.`
      ]
  },
  {
    title:"Contact Us",
    content:
      [
        `If you have any questions, concerns, or complaints regarding this Privacy Policy or our data practices, please contact us at support@wallt.in.`
      ]
  },
];

export const PrivacyPolicyEnd = `By continuing to use our Platform, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your personal information as described herein.`
