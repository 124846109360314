import * as React from "react";

import { HStack, useMediaQuery } from "@chakra-ui/react";
import WalltFullLogo from "../icons/WalltFullLogo";
import StyledButton from "./StyledButton";
import HamburgerIcon from "../icons/Hamburger";
import { Link } from "react-router-dom";

const Header = () => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  return (
    <HStack
      position="fixed"
      width="100%"
      paddingX="4%"
      paddingTop="20px"
      paddingBottom="20px"
      top={0}
      zIndex={999}
      justifyContent="space-between"
      background="#080F16CC"
    >
      <HStack gap="33%">
        {!isLargerThan800 && (
          <HamburgerIcon width="31px" height="37px" color="white" />
        )}
        <Link to="/">
          <WalltFullLogo width="146px" height={{ base: "39px", md: "40px" }} />
        </Link>
      </HStack>

      {isLargerThan800 ? (
        <HStack fontSize="16px" fontWeight={700} color="#A99EB9" gap={10}>
          {/* <Link to="/">Why WALLT</Link> */}
          <Link to="/feature">Features</Link>
          {/* <Link>Resources</Link>
          <Link>FAQs</Link>
          <Link>Contact Us</Link> */}
          <StyledButton
            py="14px"
            borderRadius="8px"
            width="133px"
            fontSize="14px"
            onClick={() => window.open("http://app.wallt.in/sign-up")}
          >
            Sign In
          </StyledButton>
        </HStack>
      ) : null}
    </HStack>
  );
};

export default Header;
