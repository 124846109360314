import { createIcon } from "@chakra-ui/icons";

const LegalIcon = createIcon({
  displayName: "Legal Icon",
  viewBox: "0 0 39 39",
  path: (
    <>
      <rect
        opacity=".2"
        x="9.383"
        y="9.391"
        width="25.573"
        height="25.573"
        rx="6.924"
        fill="#6360F9"
      />
      <path
        d="M17.569 7.374c.268 0 .299-.289.047-.444-.099-.06-.179-.394-.179-.742 0-.498.148-.78.693-1.323.525-.523.836-.69 1.283-.69.38 0 .697.176 1.87 1.29l.63.645m-4.344 1.264c-.292 0-5.13 4.823-5.13 5.113m5.13-5.113 5.403 5.273m-10.534-.16c0 .264-.292.292-.446.043-.061-.098-.396-.178-.746-.178-.88 0-2.022 1.11-2.022 1.967 0 .521.334.921 2.99 3.583 1.646 1.649 3.166 3.041 3.38 3.095.582.145 1.034-.058 1.741-.782.7-.718.81-1.317.36-1.958-.256-.364-.253-.395.039-.395m-5.296-5.375 5.296 5.375m0 0c.174 0 .723-.4 1.22-.887l.902-.888 1.666 1.665c1.482 1.482 1.644 1.705 1.468 2.031-.108.202-.197.675-.197 1.052 0 .65.152.836 2.914 3.567 2.753 2.723 2.95 2.881 3.601 2.881.612 0 .823-.133 1.907-1.213 1.085-1.08 1.219-1.29 1.219-1.899 0-.647-.159-.844-2.894-3.586-2.743-2.75-2.93-2.9-3.583-2.9-.378 0-.854.088-1.056.196-.328.174-.552.014-2.04-1.462l-1.673-1.659.892-.898c.49-.495.892-1.041.892-1.215m0 0c0-.29.031-.293.396-.039.646.45 1.246.34 1.977-.368.701-.678.943-1.243.767-1.793-.042-.133-.8-.962-1.85-2.036M5.456 27.59l.088-1.411c.052-.331.24-.682.47-.88.295-.256.382-.314 2.67-.327m8.157 2.617v-1.312c0-.303-.141-.592-.43-.88a1.474 1.474 0 0 0-1.04-.429h-2.883m-7.89 3.93c-.402.402-.43.686-.43 1.585 0 .703.07 1.224.177 1.332.132.131 1.854.176 6.842.176 5.862 0 6.692-.028 6.896-.23.277-.276.317-1.684.068-2.394-.362-1.035-.638-.939-7.061-.898H5.639c-.39 0-.764.155-1.04.43Z"
        stroke="#6360F9"
        strokeWidth="1.978"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default LegalIcon;
