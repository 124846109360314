import { Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";

const StyledButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      background="#582BD0"
      color="white"
      py="26px"
      border="1px solid #FFFFFF1F"
      borderRadius="12px"
      boxShadow="inset 0px 0px 12.3px 7px #B88EFF1C"
      backdropFilter="blur(17px)"
      _hover={{
        background:
          "radial-gradient(86.84% 86.84% at 50% 50%, rgba(108, 50, 255, 0.2) 40.15%, #411E99 100%)",
        color: "white",
      }}
      {...props}
    />
  );
};

export default StyledButton;
