import React, { useEffect } from "react";

import {
  Box,
  Center,
  HStack,
  ResponsiveValue,
  Stack,
  Text,
  Heading,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import { PrivacyPolicyStart,PrivacyPolicyBody,PrivacyPolicyEnd} from "../components/Privacy/staticData";
// import { foldData } from "../components/Feature/staticData";

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="feature-page">
      <Box position="relative" overflow="hidden" background="#17192c">
        {/* <Box
          className="feature-page-bg"
          width="100%"
          height="100vh"
          position="fixed"
        />
        <Center
          background="#080F16"
          height="850px"
          width={{ base: "150%", md: "120%", lg: "120%" }}
          transform={{
            base: "translate(-17%, -27%)",
            md: "translate(-9%, -47%)",
          }}
          borderRadius="50%"
          alignItems="flex-end"
          padding={{ base: "30% 35%", md: "9% 25%" }}
        >
          <Stack
            alignItems="center"
            textAlign="center"
            gap={{ base: 10, md: 8 }}
          >
            <Text
              fontSize={{ base: "36px", md: "60px" }}
              sx={{ fontWeight: "bold" }}
              color="white"
            >
              <Text as="span" color="#1BEBE7">
                Gift financial security
              </Text>{" "}
              to your family
            </Text>
            <Text color="#CDCAD7" fontSize={{ base: "16px", md: "22px" }}>
              At WALLT, we have curated features that aid in your journey of
              planning your legacy.
            </Text>
          </Stack>
        </Center> */}
      </Box>
      <Stack gap={{ base: 10, md: 5 }} mt={{ base: "10%", md: "5%" }} p="50px 100px" lineHeight='180%'>
        <Text fontSize="36px" fontWeight="bold" color="white" mb={{ base: "5%", md: "2%" }}>
          {PrivacyPolicyStart.title}
        </Text>
        <Text fontSize="18px" color="white" mb={{ base: "6%", md: "3%" }}>
          {PrivacyPolicyStart.content}
        </Text>
    
        {PrivacyPolicyBody.map((section, index) => (
          <Box key={index} mb={{ base: "2%", md: "1%" }}>
            <Text fontSize="24px" fontWeight="bold" color="white" mb={{ base: "3%", md: "2%" }}>
              {section.title}
            </Text>
            {section.content.map((paragraph, idx) => (
              Array.isArray(paragraph) ? (
                <UnorderedList key={idx} style={{ paddingLeft: '20px', margin: '0' }}>
                  {paragraph.map((item, itemIndex) => (
                    <ListItem key={itemIndex} color="white">
                      <Text fontSize="18px" mb={2} color="white" lineHeight='180%'>
                        {item}
                      </Text>
                    </ListItem>
                  ))}
                </UnorderedList>
              ) : (
                <Text key={idx} fontSize="18px" mb={4} color="white" lineHeight='180%'>
                  {paragraph}
                </Text>
              )
            ))}
          </Box>
        ))}

        <Text fontSize="18px" color="white">
          {PrivacyPolicyEnd}
        </Text>
      </Stack>
    </Box>
  );
}




