import { createIcon } from "@chakra-ui/icons";

const Star = createIcon({
  displayName: "Star",
  viewBox: "0 0 13 13",
  path: (
    <>
      <path
        d="m6.5 0 1.93 4.57L13 6.5 8.43 8.43 6.5 13 4.57 8.43 0 6.5l4.57-1.93L6.5 0Z"
        fill="currentColor"
      />
      ;
    </>
  ),
});

export default Star;
