import { CheckIcon } from "@chakra-ui/icons";
import {
  Center,
  Heading,
  List,
  ListItem,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";

interface IProps extends StackProps {
  data: any;
}
const InfoCard: FC<IProps> = ({ data, ...props }) => {
  return (
    <Stack spacing={12} padding="30px" {...props}>
      <Heading
        fontSize={{ base: "23px", md: "50px" }}
        fontWeight={700}
        color="white"
      >
        {data.header}
      </Heading>
      <Stack
        color="#979BB9"
        fontSize={{ base: "16px", md: "18px" }}
        spacing={1}
      >
        <Text>{data.content}</Text>
        <List padding={0}>
          {data.list.map((item: any, index: number) => (
            <ListItem key={index} my={{ base: 2, md: "40px" }}>
              <Stack direction="row" alignItems="center">
                <Center
                  width="20px"
                  height="20px"
                  background="#7A32F1"
                  color="white"
                  fontSize="12px"
                  borderRadius="full"
                >
                  <CheckIcon />
                </Center>
                <Text>{item}</Text>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Stack>
  );
};

export default InfoCard;
