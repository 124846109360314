import { createIcon } from "@chakra-ui/icons";

const Tick = createIcon({
  displayName: "Tick",
  viewBox: "0 0 278 278",
  path: (
    <>
      <circle
        cx="139.6"
        cy="138.238"
        r="138.238"
        fill="#41297A"
        fillOpacity=".9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M216.878 253.302c-21.512 13.804-47.099 21.809-74.556 21.809-76.347 0-138.238-61.891-138.238-138.238 0-47.13 23.585-88.752 59.598-113.706C25.379 47.745 0 90.707 0 139.597c0 76.346 61.891 138.238 138.238 138.238 29.216 0 56.316-9.064 78.64-24.533Z"
        fill="#401970"
        fillOpacity=".8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M214.025 255.001c38.352-24.569 63.771-67.559 63.771-116.487C277.796 62.168 215.904.276 139.557.276 110.377.276 83.308 9.318 61 24.752 82.493 10.983 108.048 3 135.467 3c76.347 0 138.238 61.891 138.238 138.238 0 47.167-23.621 88.816-59.68 113.763Z"
        fill="#fff"
      />
      <path
        d="m129.896 151.916 60.422-72.455c7.466-8.953 21.069-9.396 29.101-.947 6.883 7.24 7.179 18.513.686 26.104l-82.959 96.987c-7.954 9.299-22.319 9.339-30.325.085l-45.787-52.932c-6.786-7.845-6.432-19.58.814-27.002 8.392-8.596 22.423-7.884 29.902 1.519l22.64 28.461c3.944 4.959 11.447 5.046 15.506.18Z"
        fill="#1BEBE7"
      />
    </>
  ),
});

export default Tick;
